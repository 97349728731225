<template>
  <v-card
    elevation="3"
    :outlined="type == 'filter'"
    :class="`cr-todo-modal cr-todo-${type}`"
    :style="style"
    v-click-outside="clickOutside"
  >
    <SelectedModal v-if="type == 'selected'" />
    <FilterModal v-if="type == 'filter'" />
    <GanttSettingModal v-if="type == 'gantt-setting'" />
  </v-card>
</template>

<style lang="scss" scoped>
.cr-todo-modal {
  position: absolute;
  z-index: 5;
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import SelectedModal from "./modal/SelectedModal.vue";
import FilterModal from "./modal/FilterModal.vue";
import GanttSettingModal from "./modal/GanttSettingModal.vue";

export default {
  components: {
    SelectedModal,
    FilterModal,
    GanttSettingModal
  },
  mounted() {
    document.addEventListener("keydown", this.esc);
  },
  destroyed() {
    document.removeEventListener("keydown", this.esc);
  },
  data() {
    return {
      style: "top: 0px;"
    };
  },
  computed: {
    ...mapGetters("todoModal", ["type", "params"])
  },
  watch: {
    params(n) {
      if (Object.keys(n).length === 0) return;
      const unit = u => (typeof u === "number" ? "px" : "");

      this.$nextTick(() => {
        const { offsetWidth: listWidth } = document.getElementById("todoList");
        const { offsetWidth: modalWidth } = this.$el;
        const { top, bottom, left, right, targetWidth } = n;
        let style = "";
        if (top !== undefined) style += ` top: ${top}${unit(top)};`;
        if (bottom !== undefined) style += ` bottom: ${bottom}${unit(bottom)};`;
        if (left !== undefined) {
          // 모달창 열릴때 오른쪽 공간 없을때 처리
          if (typeof left === "number" && left + modalWidth >= listWidth) {
            style += ` right: ${listWidth - left - targetWidth}px;`;
          } else {
            style += ` left: ${left}${unit(left)}`;
          }
        }
        if (right !== undefined) {
          style += ` right: ${right}px`;
        }

        this.style = style;
      });
    },
    type(n, p) {
      if (!n) return;
      if (p === "selected") {
        this.RESET_SELECTED_ITEMS();
      }
    }
  },
  methods: {
    ...mapMutations("todoModal", ["CLOSE_MODAL"]),
    ...mapMutations("todoItem", ["RESET_SELECTED_ITEMS"]),
    clickOutside(e) {
      if (e.target.closest(".cr-modal-close-prevent")) return;
      if (this.type === "selected") return;

      this.CLOSE_MODAL();
    },
    esc(e) {
      const { name, params } = this.$route;
      const { itemId } = JSON.parse(params?.actionObj || "{}");

      if (
        e.keyCode !== 27 ||
        (this.type === "selected" && name === "todo_board_action" && !!itemId)
      ) {
        return;
      }

      if (this.type === "selected") {
        this.RESET_SELECTED_ITEMS();
      }
      this.CLOSE_MODAL();
    }
  }
};
</script>
