<template>
  <div
    class="d-flex align-center justify-center cr-todo-check-wrapper"
    :class="{ 'cr-item-detail': isDetail, 'cr-item-kanban': isKanban }"
    @click="check"
  >
    <v-icon v-if="inputValue" color="rgb(0, 202, 114)">mdi-check-bold</v-icon>
  </div>
</template>

<style lang="scss" scoped>
.cr-todo-check-wrapper {
  position: relative;
  cursor: pointer;
  height: 100%;
  &:hover::before {
    content: "";
    position: absolute;
    background-color: currentColor;
    opacity: 0.1;
    width: 100%;
    height: 100%;
    left: 0px;
  }
}
</style>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    header: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    isKanban: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isNotEmptyItem() {
      return (
        this.item?.constructor === Object && Object.keys(this.item).length > 0
      );
    },
    inputValue() {
      if (this.isNotEmptyItem) {
        return this.item[this.header.value];
      }

      return "";
    }
  },
  methods: {
    ...mapActions("todoItem", ["updateItem"]),
    async check() {
      const { id: groupId, boardId } = this.group;
      const { id: itemId, parentId } = this.item ?? {};
      const { value: headerValue } = this.header;

      await this.updateItem({
        boardId,
        groupId,
        itemId,
        parentId,
        headerValue,
        itemValue: this.inputValue ? "" : "checked",
        prevValue: this.inputValue
      });
    }
  }
};
</script>
