<template>
  <div class="mr-2" style="width: 270px;">
    <v-autocomplete
      v-if="condition.el == 'select'"
      dense
      chips
      clearable
      deletable-chips
      multiple
      small-chips
      outlined
      hide-details
      return-object
      ref="acRef"
      label="값"
      :items="valueSelectItems(filter)"
      item-text="title"
      item-value="title"
      :menu-props="{
        'offset-y': true,
        closeOnClick: true,
        contentClass: 'cr-modal-close-prevent'
      }"
      v-model="filter.values"
      @change="SET_FILTERS([...filters.map(f => ({ ...f }))])"
      @focus="acFocus"
    >
      <template v-slot:selection="{ item }">
        <v-chip small close label @click:close="closeChip(filter, item)">
          <div class="text-truncate" style="max-width: 120px;">
            <span>{{ item.title }}</span>
          </div>
        </v-chip>
      </template>
    </v-autocomplete>

    <v-menu
      v-else-if="condition.el == 'cal'"
      v-model="dateMenu"
      offset-y
      ref="dateMenu"
      transition="scale-transition"
      content-class="cr-modal-close-prevent"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          v-bind="attrs"
          v-on="on"
          dense
          readonly
          outlined
          hide-details
          class="cr-dp"
          label="날짜 선택"
          autocomplete="off"
        />
      </template>
      <v-date-picker
        v-model="date"
        no-title
        scrollable
        color="primary"
        :range="dateRange"
        @click:date="clickDate"
      >
        <v-checkbox
          v-model="dateRange"
          samll
          hide-details
          label="범위로 지정"
          class="ma-0 pa-1"
        />
      </v-date-picker>
    </v-menu>

    <v-text-field
      v-else-if="condition.el == 'input'"
      dense
      outlined
      hide-details
      :value="textValue"
      @input="txt => textChange(txt, filter)"
    />
    <v-text-field
      v-else
      dense
      disabled
      outlined
      hide-details
      background-color="#ededed"
    />
  </div>
</template>

<style lang="scss" scoped>
.v-input.v-select.v-autocomplete::v-deep {
  .v-select__selections {
    overflow-x: scroll;
    flex-wrap: nowrap;
    padding: 0px !important;
    margin-top: 8px;
    min-height: 30px !important;

    .v-chip {
      flex: 0 0 auto;
      height: 20px;
      padding: 0px 4px;
    }

    input {
      padding: 0px !important;
    }
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import headerUtils from "@/todo/store/utils/header";
let timeout;

export default {
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    filter: {
      type: Object,
      default: () => ({})
    }
  },
  data({ $props }) {
    const [values] = $props.filter.values;
    const value = values?.value?.split(",");

    let dateRange = true;
    let date = [];
    if (value?.length == 1) {
      dateRange = false;
      date = [...value][0];
    }

    if (value?.length == 2) {
      dateRange = true;
      date = [...value];
    }

    return {
      dateMenu: false,
      dateRange,
      date
    };
  },
  computed: {
    ...mapGetters("todoItem", ["orgItems"]),
    textValue() {
      return this.filter.values[0]?.value ?? "";
    },
    condition() {
      return this.filter?.condition ?? {};
    },
    valueSelectItems() {
      return filter => {
        const { value, type, labels } = filter.column ?? {};
        const { el } = filter.condition ?? {};

        if (value && el == "select") {
          if (type == headerUtils.STATUS().type) {
            return labels;
          }

          if (type == headerUtils.TIMELINE().type) {
            return [
              { title: "현재", value: "present" },
              { title: "오늘까지", value: "expiry" },
              { title: "미래", value: "comming" },
              { title: "과거", value: "past" }
            ];
          }

          const arr = [];
          Object.keys(this.orgItems).forEach(key => {
            this.orgItems[key].forEach(item => {
              switch (type) {
                case headerUtils.TITLE().type:
                case headerUtils.TEXT().type:
                case headerUtils.CREATED_LOG().type:
                case headerUtils.LINK().type: {
                  if (item[value]) {
                    arr.push({ title: item[value], value: item[value] });
                  }
                  break;
                }
                case headerUtils.PERSON().type: {
                  if (item[value]) {
                    item[value].split(";").forEach(p => {
                      try {
                        const { name, email } = JSON.parse(p);
                        arr.push({
                          title: `"${name}" <${email}>`,
                          value: p
                        });
                      } catch (e) {
                        //
                      }
                    });
                  }
                  break;
                }
                default:
                  break;
              }
            });
          });

          const set = new Set(arr);
          return [...set];
        }

        return [];
      };
    }
  },
  methods: {
    ...mapMutations("todoFilter", ["SET_FILTERS"]),
    acFocus() {
      // 스크롤 영역 클릭시 메뉴 안열려서 직접 열어줘야함.
      setTimeout(() => {
        if (this.$refs.acRef) {
          if (!this.$refs.acRef.$refs.menu.isActive) {
            this.$refs.acRef.activateMenu();
          }
        }
      }, 0);
    },
    closeChip(filter, item) {
      filter.values = filter.values.filter(v => v.value !== item.value);
      this.SET_FILTERS([...this.filters.map(f => ({ ...f }))]);
    },
    textChange(txt, filter) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      timeout = setTimeout(() => {
        filter.values = [{ title: txt, value: txt }];
        this.SET_FILTERS([...this.filters.map(f => ({ ...f }))]);
      }, 500);
    },
    clickDate(date) {
      if (this.dateRange) {
        const [sd, ed] = this.date.sort();
        if (!sd || !ed) return;

        this.filter.values = [{ title: "", value: this.date.join(",") }];
      } else {
        this.filter.values = [{ title: date, value: date }];
      }

      this.SET_FILTERS([...this.filters.map(f => ({ ...f }))]);
      this.dateMenu = false;
    }
  }
};
</script>
