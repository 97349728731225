<template>
  <div
    :style="`height: 100%; width: ${header.width}px;`"
    class="d-flex justify-space-between align-center px-2 cr-header-title-wrapper"
    :class="{ 'cr-item-detail': isDetail }"
  >
    <!-- 타이틀 표시 -->
    <div v-if="!editable" :class="titleCls" @click.stop="changeEditable">
      {{ header.title }}
    </div>
    <!-- 수정 필드 -->
    <v-text-field
      v-else
      ref="textRef"
      outlined
      dense
      hide-details
      class="text-subtitle-1 font-weight-medium"
      :value="header.title"
      @keydown.esc="$refs.textRef.blur()"
      @keydown.enter="updateTitle"
      @blur="editable = false"
    />
    <!-- 더보기 메뉴 -->
    <v-btn
      v-if="showDotsBtn"
      icon
      class="ml-1 cr-menu-close-prevent"
      @click.stop="openMenu"
    >
      <v-icon>mdi-dots-horizontal</v-icon>
    </v-btn>
    <!-- 사이즈 조절 -->
    <div class="cr-col-resize" @mousedown="e => $emit('mousedown', e)" />
  </div>
</template>

<style lang="scss" scoped>
.cr-header-title-wrapper {
  .cr-header-title {
    width: 100%;
    padding: 5px;
    text-align: center;
    border: thin solid transparent;

    &.cr-cursor-text {
      cursor: text;
    }
    &.cr-hover {
      border-color: rgba(0, 0, 0, 0.12);
    }
  }

  .v-text-field ::v-deep {
    .v-input__control {
      .v-input__slot {
        min-height: 38px;
        padding-left: 6px;
      }
    }
  }

  .cr-col-resize:hover {
    border-right-color: var(--v-primary-base) !important;
  }

  &.cr-item-detail {
    width: 100% !important;
    padding: 0px !important;
    .cr-header-title {
      border-color: rgba(0, 0, 0, 0.12);
    }
  }
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import headerUtils from "@/todo/store/utils/header";
import { isBlank } from "@/commons/utils/validation";

export default {
  props: {
    header: {
      type: Object,
      default: () => ({})
    },
    group: {
      type: Object,
      default: () => ({})
    },
    hover: {
      type: Boolean,
      default: false
    },
    isDetail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { editable: false };
  },
  computed: {
    ...mapGetters("sidePanel", { sidePanelWidth: "renderedWidth" }),
    ...mapGetters("todoRoute", ["routeBoardId"]),
    ...mapGetters("dragDrop", ["dragMode"]),
    ...mapGetters("todoMenu", {
      menuType: "type",
      headerValue: "headerValue",
      groupId: "groupId"
    }),
    isTitle() {
      return this.header.type == headerUtils.TITLE().type;
    },
    titleCls() {
      let cls =
        "text-subtitle-1 font-weight-bold text-truncate cr-header-title";
      if (!this.isTitle) cls += " cr-cursor-text";
      if (this.hover && !this.dragMode && !this.isTitle) cls += " cr-hover";

      return cls;
    },
    showDotsBtn() {
      return (
        (!this.isTitle && this.hover && !this.dragMode && !this.editable) ||
        (this.menuType === "header" &&
          this.headerValue == this.header.value &&
          this.groupId == this.group.id)
      );
    }
  },
  watch: {
    dragMode(mode) {
      if (!mode) return;
      this.$refs.textRef?.blur();
    }
  },
  methods: {
    ...mapMutations("todoMenu", ["CLOSE_MENU"]),
    ...mapActions("todoHeader", ["updateHeader"]),
    ...mapActions("todoMenu", ["setMenu"]),
    changeEditable() {
      if (this.isTitle) return;

      this.editable = true;
      this.$nextTick(() => {
        this.$refs.textRef.focus();
      });
    },
    updateTitle(e) {
      const title = e.target.value.trim();
      if (isBlank(title) || title == this.header.title) {
        this.$refs.textRef.blur();
        return;
      }

      this.$refs.textRef.blur();
      this.updateHeader({
        mutation: "CHANGE_HEADER",
        boardId: this.routeBoardId,
        params: {
          header: { title },
          value: this.header.value
        }
      });
    },
    openMenu(e) {
      const { top, left, height, width } = e.target.getBoundingClientRect();
      const { offsetWidth: rootWidth } = this.$root.$el;
      const { offsetWidth: listWidth } = document.getElementById("todoList");
      const appbarHeight = 64;
      const toolbarHeight = 66;
      const naviWidth = rootWidth - listWidth;

      this.setMenu({
        type: "header",
        itemId: "header" + this.group.id,
        groupId: this.group.id,
        headerValue: this.header.value,
        subType: "menu",
        top: top + height - appbarHeight - toolbarHeight,
        left: left - naviWidth + this.sidePanelWidth,
        targetWidth: width,
        targetHeight: height,
        isDetail: this.isDetail
      });
    }
  }
};
</script>
