<template>
  <div class="cr-tool-btns">
    <AddGroupBtn />
    <AddFilterBtn />
  </div>
</template>

<style lang="scss" scoped>
.cr-tool-btns::v-deep {
  padding: 5px 0px;

  .v-btn {
    height: 26px;
    min-width: auto;
    padding: 0px 8px;
    margin: 0px 4px;
    border: 1px solid;
    border-radius: 6px;
    border-color: transparent;

    &:hover {
      border-color: rgba(0, 0, 0, 0.12);
    }
    &:hover::before {
      opacity: 0.03 !important;
    }
  }
}
</style>

<script>
import AddGroupBtn from "./AddGroupBtn.vue";
import AddFilterBtn from "./AddFilterBtn.vue";

export default {
  components: { AddGroupBtn, AddFilterBtn }
};
</script>
