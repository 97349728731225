<template>
  <v-btn v-if="viewType == 'table'" tile text @click="click">
    새로운 그룹
  </v-btn>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("todoRoute", ["viewType"]),
    ...mapGetters("todoBoard", ["currentBoard"])
  },
  methods: {
    ...mapActions("todoGroup", ["addGroup"]),
    click() {
      const { id: boardId } = this.currentBoard;
      const title = this.$t("todo.새로운 그룹");

      this.addGroup({ title, boardId });
    }
  }
};
</script>
