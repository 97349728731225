<template>
  <div
    class="cr-item-number-wrapper"
    :class="{ 'cr-item-detail': isDetail, 'cr-item-kanban': isKanban }"
    :title="inputValue"
  >
    <div
      v-if="isNotEmptyItem && !editable"
      class="cr-item-number"
      :class="{
        'cr-item-drag-mode': dragMode,
        'cr-number-connected': isConnected
      }"
      @click.stop="changeEditable"
    >
      <span>
        {{ inputValue }}
        <v-icon
          v-if="!inputValue && inputValue !== 0"
          class="cr-number-icon"
          size="20"
        >
          mdi-numeric
        </v-icon>
      </span>
    </div>

    <v-text-field
      v-else
      ref="textRef"
      type="number"
      dense
      hide-details
      outlined
      :loading="loading"
      :disabled="loading"
      :value="inputValue"
      @focus="inputFocus"
      @blur="inputBlur"
      @keydown.esc="$refs.textRef.blur()"
      @keydown.enter.exact.prevent="add"
    ></v-text-field>
  </div>
</template>

<style lang="scss" scoped>
.cr-item-number-wrapper {
  height: 100%;
  min-width: 0px;
  padding: 4px 2px;

  .cr-item-number {
    cursor: text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    min-width: 0px;
    font-size: 16px;
    margin: 0px 4px;
    line-height: 27px;
    color: #424242;
    border: thin solid transparent;
    padding-left: 3px;

    .cr-number-icon {
      display: none;
      margin-left: 4px;
      margin-bottom: 2px;
    }

    &:hover {
      border-color: rgba(0, 0, 0, 0.12);

      .cr-number-icon {
        display: inline-flex;
      }
    }
    &.cr-item-drag-mode:hover {
      border-color: transparent;
      .cr-number-icon {
        display: none;
      }
    }

    &.cr-number-connected:hover {
      cursor: grab;
      border-color: transparent;
    }
  }

  .v-input.v-text-field::v-deep {
    .v-input__control {
      .v-input__slot {
        min-height: 34px;
        padding: 0 9px;
        fieldset {
          border: none;
        }
        .v-text-field__slot {
          input {
            padding: 7px 0 9px;
          }
        }
      }
    }
  }

  &.cr-item-detail {
    padding: 5px 0px;
    .cr-item-number {
      text-align: center;
      min-height: 40px;
      border: thin solid transparent;
      margin: 0px;
      padding: 1px;
      line-height: 38px;
      white-space: pre-line;
      &:hover {
        border-color: rgba(0, 0, 0, 0.12);
      }
      .cr-number-icon {
        display: inline;
      }

      &.cr-number-connected {
        .cr-number-icon {
          display: none;
        }
        &:hover {
          cursor: default;
          border-color: transparent;
          margin: 0px;
          padding: 1px;
          line-height: 38px;
        }
      }
    }

    .v-input.v-text-field::v-deep {
      margin: 1px 0px;
      .v-input__control {
        .v-input__slot {
          padding: 0px 4px;
          min-height: 40px;
          fieldset {
            border: thin solid rgba(0, 0, 0, 0.38);
          }
          input {
            line-height: 38px;
            margin: 0px;
            padding: 1px 0px;
            min-height: 40px;
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getDateWithoutTime } from "@/commons/utils/moment";

export default {
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    header: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    isKanban: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { loading: false, editable: false };
  },
  computed: {
    ...mapGetters("dragDrop", ["dragMode"]),
    isConnected() {
      return !!this.header.connectedColumn;
    },
    connectedValue() {
      return this.item[this.header.connectedColumn] ?? "";
    },
    isNotEmptyItem() {
      return (
        this.item?.constructor === Object && Object.keys(this.item).length > 0
      );
    },
    dateRange() {
      const startRange = this.connectedValue.split(",");
      if (startRange.length == 2) {
        const [first, second] = startRange;
        let sd = new Date(first);
        let ed = new Date(second);
        if (sd.getTime() > ed.getTime()) {
          sd = ed;
          ed = new Date(first);
        }

        return [sd, ed];
      }

      return null;
    },
    inputValue() {
      if (this.isConnected) {
        if (!Array.isArray(this.dateRange)) return "";

        const [start, end] = this.dateRange;
        if (this.header.format === "percent") {
          const d1 = (end - start) / (1000 * 60 * 60 * 24) + 1;
          const d2 = (new Date().getTime() - start) / (1000 * 60 * 60 * 24) + 1;
          if (d2 < 0) return 0 + "%";
          let percent = parseFloat(((Math.floor(d2) / d1) * 100).toFixed(1));
          return (percent > 100 ? 100 : percent) + "%";
        }

        const ms = end.getTime() - start.getTime();
        return (ms ? ms / (1000 * 60 * 60 * 24) + 1 : 1) + "d";
      }

      if (this.isNotEmptyItem) {
        return this.item[this.header.value];
      }

      return "";
    }
  },
  methods: {
    ...mapMutations("todItem", ["UPDATE_ITEM"]),
    ...mapActions("todoItem", ["updateItem"]),
    changeEditable() {
      if (this.isConnected) return;
      this.editable = true;
      this.$nextTick(() => {
        this.$refs.textRef.focus();
      });
    },
    inputFocus() {
      if (this.isDetail || this.isKanban) return;
      this.$el.parentNode.style.border = "1px solid var(--v-primary-base)";
      this.$el.style.paddingLeft = "0px";
      this.$el.style.paddingTop = "2px";
      this.$el.style.paddingBottom = "0px";
    },
    inputBlur() {
      this.$el.parentNode.style.border = "";
      this.$el.style.paddingLeft = "";
      this.$el.style.paddingTop = "";
      this.$el.style.paddingBottom = "";
      this.editable = false;
      this.$refs.textRef?.reset();
    },
    async add(e) {
      const text = e.target.value.trim();
      if (this.inputValue == text) {
        this.$refs.textRef.blur();
        return;
      }

      const { id: groupId, boardId } = this.group;
      const { id: itemId, parentId } = this.item ?? {};
      const { value: headerValue, connectedColumn } = this.header;
      this.loading = true;

      if (this.isConnected) {
        let startDay = new Date();
        let endDay = new Date();
        endDay = new Date(
          endDay.setDate(endDay.getDate() + (parseInt(text) - 1))
        );

        if (Array.isArray(this.dateRange)) {
          startDay = this.dateRange[0];
          endDay = new Date(startDay);
          endDay = new Date(
            endDay.setDate(endDay.getDate() + (parseInt(text) - 1))
          );
        }

        this.UPDATE_ITEM({
          parentId,
          groupId,
          itemId,
          headerValue: connectedColumn,
          itemValue: [
            getDateWithoutTime(startDay, "YYYY-MM-DD"),
            getDateWithoutTime(endDay, "YYYY-MM-DD")
          ].join(",")
        });
      } else {
        await this.updateItem({
          boardId,
          groupId,
          itemId,
          parentId,
          headerValue,
          itemValue: text,
          prevValue: this.inputValue
        });
      }

      this.loading = false;
      if (this.$refs.textRef) {
        this.$refs.textRef.blur();
      }
    }
  }
};
</script>
