import headerUtils from "@/todo/store/utils/header";
const key = "crm_2";

export default {
  /**
   * headerUtils.TITLE, headerUtils.ADDITIONAL 두개는 필수 값 (정해진 순서대로)
   */
  genHeader: () => [
    { ...headerUtils.TITLE(), value: key + "_title" },
    {
      ...headerUtils.STATUS(),
      value: key + "_status_1",
      title: "todo.CRM#2_월",
      width: 110,
      labels: [
        {
          title: "todo.CRM#2_1월",
          value: `${key}_status_1_1`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#2_2월",
          value: `${key}_status_1_2`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#2_3월",
          value: `${key}_status_1_3`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#2_4월",
          value: `${key}_status_1_4`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#2_5월",
          value: `${key}_status_1_5`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#2_6월",
          value: `${key}_status_1_6`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#2_7월",
          value: `${key}_status_1_7`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#2_8월",
          value: `${key}_status_1_8`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#2_9월",
          value: `${key}_status_1_9`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#2_10월",
          value: `${key}_status_1_10`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#2_11월",
          value: `${key}_status_1_11`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#2_12월",
          value: `${key}_status_1_12`,
          color: "#FFFFFF"
        }
      ]
    },
    {
      ...headerUtils.PERSON(),
      value: key + "_person_1",
      title: "todo.CRM#2_영업대표",
      width: 145
    },
    {
      ...headerUtils.STATUS(),
      value: key + "_status_2",
      title: "todo.CRM#2_구분",
      width: 110,
      labels: [
        {
          title: "todo.CRM#2_수주",
          color: "#e0e0e0",
          value: `${key}_status_2_1`
        },
        {
          title: "todo.CRM#2_매출",
          color: "#4caf50",
          value: `${key}_status_2_2`
        },
        {
          title: "todo.CRM#2_수금",
          color: "#3f51b5",
          value: `${key}_status_2_3`
        }
      ]
    },
    {
      ...headerUtils.NUMBER(),
      value: key + "_number_1",
      title: "todo.CRM#2_당월 목표",
      width: 120
    },
    {
      ...headerUtils.NUMBER(),
      value: key + "_number_2",
      title: "todo.CRM#2_실적",
      width: 120
    },
    {
      ...headerUtils.NUMBER(),
      value: key + "_number_3",
      title: "todo.CRM#2_GAP",
      width: 120
    },
    {
      ...headerUtils.NUMBER(),
      value: key + "_number_4",
      title: "todo.CRM#2_익월 목표",
      width: 120
    },
    {
      ...headerUtils.TEXT(),
      value: key + "_desc_1",
      title: "todo.CRM#2_상세",
      width: 220
    },
    { ...headerUtils.ADDITIONAL() }
  ],
  genGroups: () => [
    { title: "todo.CRM#2_1Q", color: "#424242" },
    { title: "todo.CRM#2_2Q", color: "#424242" },
    { title: "todo.CRM#2_3Q", color: "#424242" },
    { title: "todo.CRM#2_4Q", color: "#424242" }
  ],
  /**
   * genGroups의 size와 genItems 배열안에 배열개수를 맞춰줘야함.
   * ex) groups: [{}, {}] , items: [[{}, {}, {}], []]
   */
  genItems: () => [
    [
      {
        [key + "_title"]: "todo.CRM#2_item-1",
        [key + "_status_1"]: `${key}_status_1_3`,
        [key + "_status_2"]: `${key}_status_2_1`,
        [key + "_number_1"]: "1000",
        [key + "_number_2"]: "500",
        [key + "_number_3"]: "500",
        [key + "_number_4"]: "1500"
      },
      {
        [key + "_title"]: "todo.CRM#2_item-2",
        [key + "_status_1"]: `${key}_status_1_1`,
        [key + "_status_2"]: `${key}_status_2_2`,
        [key + "_number_1"]: "1500",
        [key + "_number_2"]: "0",
        [key + "_number_3"]: "-500",
        [key + "_number_4"]: "3000"
      }
    ],
    [
      {
        [key + "_title"]: "todo.CRM#2_item-3",
        [key + "_status_1"]: `${key}_status_1_5`,
        [key + "_status_2"]: `${key}_status_2_1`,
        [key + "_number_1"]: "500",
        [key + "_number_2"]: "0",
        [key + "_number_3"]: "0",
        [key + "_number_4"]: "0"
      }
    ],
    [],
    []
  ]
};
