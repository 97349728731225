<template>
  <div
    class="cr-item-text-wrapper"
    :class="{ 'cr-item-detail': isDetail, 'cr-item-kanban': isKanban }"
    :title="inputValue"
  >
    <div
      v-if="isNotEmptyItem && !editable"
      class="cr-item-text"
      :class="
        `${dragMode ? 'cr-item-drag-mode' : ''} ${inputValue ? '' : 'cr-empty'}`
      "
      @click.stop="changeEditable"
    >
      {{ inputValue }}
      <v-icon v-if="!inputValue" class="cr-text-icon" size="18">
        mdi-pencil-plus-outline
      </v-icon>
    </div>

    <v-textarea
      v-else
      ref="textRef"
      dense
      hide-details
      hide-spin-buttons
      no-resize
      outlined
      class="cr-edit-text"
      :wrap="isNotEmptyItem && !isDetail ? 'off' : ''"
      :auto-grow="isDetail"
      :rows="rows"
      :loading="loading"
      :disabled="loading"
      v-model="text"
      @focus="inputFocus"
      @blur="inputBlur"
      @keydown.esc="$refs.textRef.blur()"
      @keydown.enter.exact.prevent="add"
      @keydown.enter.shift.exact.prevent="shiftEnter"
    ></v-textarea>
  </div>
</template>

<style lang="scss" scoped>
.cr-item-text-wrapper {
  height: 100%;
  min-width: 0px;
  padding: 4px 2px;

  .cr-item-text {
    cursor: text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    min-width: 0px;
    font-size: 16px;
    margin: 0px 4px;
    line-height: 27px;
    color: #424242;
    border: thin solid transparent;
    padding-left: 3px;

    .cr-text-icon {
      display: none;
      margin-left: 4px;
    }

    &:hover {
      border-color: rgba(0, 0, 0, 0.12);
      .cr-text-icon {
        display: inline;
      }
    }
    &.cr-item-drag-mode:hover {
      border-color: transparent;
      .cr-text-icon {
        display: none;
      }
    }
  }
  .v-input.v-text-field::v-deep {
    .v-input__control {
      .v-input__slot {
        min-height: 34px;
        padding: 0 9px;
        fieldset {
          border: none;
        }
        .v-text-field__slot {
          textarea {
            margin-top: 2px;
            min-height: 30px;
            height: 30px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  // 상세화면에서 스타일적용
  &.cr-item-detail {
    padding: 5px 0px;
    .cr-item-text {
      overflow-y: auto;
      min-height: 40px;
      max-height: 380px;
      padding: 1px 3px;
      margin: 0px;
      line-height: 38px;
      white-space: pre-line;
      border: thin solid transparent;
      &.cr-empty {
        text-align: center;
      }

      .cr-text-icon {
        display: inline;
        font-size: 18px;
      }

      &:hover {
        border-color: rgba(0, 0, 0, 0.12);
      }
    }

    .v-input.v-text-field::v-deep {
      margin: 0px;
      .v-input__control {
        .v-input__slot {
          padding: 1px 4px;
          min-height: 40px;
          fieldset {
            border: thin solid rgba(0, 0, 0, 0.38);
          }
          .v-text-field__slot {
            margin-right: -3px;
            textarea {
              line-height: 38px;
              margin: 0px;
              padding: 1px 0px;
              min-height: 40px;
              max-height: 378px;
              overflow: auto;

              &::-webkit-scrollbar {
                width: 20px;
              }
              &::-webkit-scrollbar-thumb {
                background-clip: padding-box;
                border: 6px solid transparent;
                border-right-width: 4px;
                border-left-width: 7px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    header: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    isKanban: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isNotEmptyItem) {
        this.text = this.item[this.header.value];
      }
    });
  },
  data() {
    return {
      loading: false,
      editable: false,
      text: ""
    };
  },
  computed: {
    ...mapGetters("dragDrop", ["dragMode"]),
    isNotEmptyItem() {
      return (
        this.item?.constructor === Object && Object.keys(this.item).length > 0
      );
    },
    inputValue() {
      if (this.isNotEmptyItem) {
        return this.item[this.header.value];
      }

      return "";
    },
    rows() {
      let rows = 1;
      if (this.isDetail && this.text) {
        // rows = this.text.split("\n").length;
        // rows = rows > 10 ? 10 : rows;
        rows = Math.floor(this.$el.offsetHeight / 38);
        rows = rows > 10 ? 10 : rows;
      }
      return rows;
    }
  },
  watch: {
    inputValue(value) {
      this.text = value;
    }
  },
  methods: {
    ...mapActions("todoItem", ["updateItem"]),
    changeEditable() {
      this.editable = true;
      this.$nextTick(() => {
        this.$refs.textRef.focus();
      });
    },
    inputFocus() {
      if (this.isDetail || this.isKanban) return;
      this.$el.parentNode.style.border = "1px solid var(--v-primary-base)";
      this.$el.style.paddingLeft = "0px";
      this.$el.style.paddingTop = "2px";
      this.$el.style.paddingBottom = "0px";
    },
    inputBlur() {
      this.$el.parentNode.style.border = "";
      this.$el.style.paddingLeft = "";
      this.$el.style.paddingTop = "";
      this.$el.style.paddingBottom = "";
      this.editable = false;
      this.$refs.textRef?.reset();
      this.text = this.inputValue;
    },
    async add(e) {
      const text = e.target.value.trim();
      if (this.inputValue == text) {
        this.$refs.textRef.blur();
        return;
      }

      const { id: groupId, boardId } = this.group;
      const { id: itemId, parentId } = this.item ?? {};
      const { value: headerValue } = this.header;

      this.loading = true;

      await this.updateItem({
        boardId,
        groupId,
        itemId,
        parentId,
        headerValue,
        itemValue: text,
        prevValue: this.inputValue
      });

      this.loading = false;
      if (this.$refs.textRef) {
        this.$refs.textRef.blur();
      }
    },
    shiftEnter() {
      this.text += "\n";
      this.$nextTick(() => {
        const el = this.$el.querySelector("textarea");
        if (el) el.scrollTop = el.scrollHeight;
      });
    }
  }
};
</script>
