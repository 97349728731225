<template>
  <v-tabs v-model="selectedTab" height="30" show-arrows>
    <Tab
      v-for="(filter, idx) in filters"
      :key="`${filter.id}_${filter.filterName}`"
      :filter="filter"
      :isSelected="selectedTab === idx"
    />
  </v-tabs>
</template>

<script>
import { mapGetters } from "vuex";
import Tab from "./tab";

export default {
  components: { Tab },
  data({ $store: { getters } }) {
    const routeFilterId = getters["todoRoute/routeFilterId"];
    const savedFilters = getters["todoFilter/savedFilters"];
    const index = savedFilters?.findIndex(f => f.id == routeFilterId);

    return { selectedTab: index === -1 ? 0 : index + 1 };
  },
  computed: {
    ...mapGetters("todoRoute", ["routeFilterId"]),
    ...mapGetters("todoFilter", ["savedFilters"]),
    filters() {
      return [{ id: 0, filterName: "기본" }, ...this.savedFilters];
    }
  },
  watch: {
    // 앞으로가기 뒤로가기시 필터 변경
    routeFilterId(rfi) {
      const index = this.savedFilters.findIndex(f => f.id == rfi);
      this.selectedTab = index == -1 ? 0 : index + 1;
    }
  }
};
</script>
