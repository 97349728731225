<template>
  <v-btn
    tile
    text
    :color="activeNewFilter ? 'primary' : ''"
    :class="{ 'cr-filter-active': activeNewFilter }"
    @click="click"
  >
    <v-icon size="15" class="mr-1" color="grey darken-1">
      mdi-filter-plus
    </v-icon>
    <span>새 필터</span>
  </v-btn>
</template>

<style lang="scss" scoped>
.cr-filter-active::v-deep {
  &::before {
    background-color: var(--v-primary-base);
    opacity: 0.08;
  }

  .v-icon {
    color: var(--v-primary-base) !important;
  }
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters("todoFilter", ["filtersIds"]),
    ...mapGetters("todoRoute", ["routeState", "routeFilterId", "viewType"]),
    ...mapGetters("todoModal", { modalType: "type", modalParams: "params" }),
    activeNewFilter() {
      const { isFilter } = this.filtersIds;
      return isFilter && !this.routeFilterId;
    }
  },
  methods: {
    ...mapMutations("todoModal", ["SET_MODAL"]),
    ...mapActions("todoRoute", ["routeBoardAction"]),
    click() {
      // 현재 탭이 기본이 아닐경우 기본 탭으로 이동
      if (this.routeFilterId !== 0) {
        const { workspaceId, boardId, viewType } = this.routeState;
        const actionObj = JSON.stringify({ filterId: 0, viewType });
        this.routeBoardAction({ workspaceId, boardId, actionObj });
      }

      // 이미 열려있을때 닫기위해 return
      const { type } = this.modalParams;
      if (this.modalType === "filter" && type === "add") return;

      // 필터 모달 오픈
      setTimeout(() => {
        const left = this.todoLayout === 0 ? 80 : 0;
        const params = { filterId: 0, type: "add", top: 37, left };
        this.SET_MODAL({ type: "filter", params });
      }, 0);
    }
  }
};
</script>
