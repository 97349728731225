import headerUtils from "@/todo/store/utils/header";
const key = "hr_2";

export default {
  /**
   * headerUtils.TITLE, headerUtils.ADDITIONAL 두개는 필수 값 (정해진 순서대로)
   */
  genHeader: () => [
    { ...headerUtils.TITLE(), value: key + "_title", width: 430 },
    {
      ...headerUtils.PERSON(),
      value: key + "_person",
      title: "todo.HR#2_담당자",
      width: 130
    },
    {
      ...headerUtils.TIMELINE(),
      value: key + "_timeline_1",
      title: "todo.HR#2_마감일"
    },
    { ...headerUtils.ADDITIONAL() }
  ],
  genGroups: () => [
    { title: "todo.HR#2_서류 작업", color: "#616161" },
    { title: "todo.HR#2_IT", color: "#616161" },
    { title: "todo.HR#2_복리후생", color: "#616161" },
    { title: "todo.HR#2_회의 및 커뮤니케이션", color: "#616161" },
    { title: "todo.HR#2_재무", color: "#616161" },
    { title: "todo.HR#2_기타", color: "#616161" }
  ],
  /**
   * genGroups의 size와 genItems 배열안에 배열개수를 맞춰줘야함.
   * ex) groups: [{}, {}] , items: [[{}, {}, {}], []]
   */
  genItems: () => [
    [
      // 서류 작업
      {
        [key + "_title"]: "todo.HR#2_item-1"
      },
      {
        [key + "_title"]: "todo.HR#2_item-2"
      },
      {
        [key + "_title"]: "todo.HR#2_item-3"
      }
    ],
    [
      // IT
      {
        [key + "_title"]: "todo.HR#2_item-4"
      },
      {
        [key + "_title"]: "todo.HR#2_item-5"
      }
    ],
    [
      // 복리후생
      {
        [key + "_title"]: "todo.HR#2_item-6"
      }
    ],
    [
      // 회의 및 커뮤니케이션
      {
        [key + "_title"]: "todo.HR#2_item-7"
      }
    ],
    [
      // 재무
      {
        [key + "_title"]: "todo.HR#2_item-8"
      },
      {
        [key + "_title"]: "todo.HR#2_item-9"
      }
    ],
    [
      // 기타
      {
        [key + "_title"]: "todo.HR#2_item-10"
      },
      {
        [key + "_title"]: "todo.HR#2_item-11"
      }
    ]
  ]
};
