<template>
  <div v-show="!show" class="cr-todo-toolbar">
    <div class="d-flex justify-space-between">
      <Btns />
      <ToggleBtns />
    </div>

    <FilterTabs />
  </div>
</template>

<style lang="scss" scoped>
.cr-todo-toolbar {
  background: #fff;
  height: 66px;
  z-index: 4;
}
</style>

<script>
import { mapGetters } from "vuex";
import Btns from "./btns";
import ToggleBtns from "./toggleBtns";
import FilterTabs from "./filterTabs";

export default {
  components: { Btns, ToggleBtns, FilterTabs },
  computed: {
    ...mapGetters("todoDetail", ["show"])
  }
};
</script>
