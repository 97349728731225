<template>
  <div style="height: 100%;" class="cr-drag-prevent">
    <Select
      v-if="!isDetail && item.parentId"
      v-on="$listeners"
      key="title"
      :item="item"
      :checked="checked"
    />
    <div v-if="!isDetail && item.parentId" class="cr-border" />
    <SubBtn
      v-if="!isDetail && !editable && !dragMode"
      :item="item"
      :group="group"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-border {
  top: 0px;
  left: 43px;
  width: 1px;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.08);
}
</style>

<script>
import { mapGetters } from "vuex";
import SubBtn from "./SubBtn";
import Select from "../Select.vue";

export default {
  components: { SubBtn, Select },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    group: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("dragDrop", ["dragMode"])
  }
};
</script>
