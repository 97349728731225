import defaultTmpl from "./tmpl/defaultTmpl";

import pri_1 from "./tmpl/pri_1";
import pri_2 from "./tmpl/pri_2";

import hr_1 from "./tmpl/hr_1";
import hr_2 from "./tmpl/hr_2";

import prj_1 from "./tmpl/prj_1";
import prj_2 from "./tmpl/prj_2";
import prj_3 from "./tmpl/prj_3";

import crm_1 from "./tmpl/crm_1";
import crm_2 from "./tmpl/crm_2";
import crm_3 from "./tmpl/crm_3";

export default {
  "todo.DEFAULT": [
    {
      key: "defaultTmpl",
      title: "todo.새로운 보드",
      text: "",
      img: "tpl_default.png",
      ...defaultTmpl
    }
  ],
  "todo.PRI": [
    {
      key: "pri_1",
      title: "todo.PRI#1_title",
      text: "todo.PRI#1_text",
      img: "PRI-1.png",
      ...pri_1
    },
    {
      key: "pri_2",
      title: "todo.PRI#2_title",
      text: "todo.PRI#2_text",
      img: "PRI-2.png",
      ...pri_2
    }
  ],
  "todo.HR": [
    {
      key: "hr_1",
      title: "todo.HR#1_title",
      text: "todo.HR#1_text",
      img: "HR-1.png",
      ...hr_1
    },
    {
      key: "hr_2",
      title: "todo.HR#2_title",
      text: "todo.HR#2_text",
      img: "HR-2.png",
      ...hr_2
    }
  ],
  "todo.SW": [
    {
      key: "prj_1",
      title: "todo.PRJ#1_title",
      text: "todo.PRJ#1_text",
      img: "PRJ-1.png",
      ...prj_1
    },
    {
      key: "prj_2",
      title: "todo.PRJ#2_title",
      text: "todo.PRJ#2_text",
      img: "PRJ-2.png",
      ...prj_2
    },
    {
      key: "prj_3",
      title: "todo.PRJ#3_title",
      text: "todo.PRJ#3_text",
      img: "PRJ-3.png",
      ...prj_3
    }
  ],
  "todo.CRM": [
    {
      key: "crm_1",
      title: "todo.CRM#1_title",
      text: "todo.CRM#1_text",
      img: "CRM-1.png",
      ...crm_1
    },
    {
      key: "crm_2",
      title: "todo.CRM#2_title",
      text: "todo.CRM#2_text",
      img: "CRM-2.png",
      ...crm_2
    },
    {
      key: "crm_3",
      title: "todo.CRM#3_title",
      text: "todo.CRM#3_text",
      img: "CRM-3.png",
      ...crm_3
    }
  ]
};
