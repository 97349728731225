<template>
  <v-card-text class="flex-column">
    <div
      v-for="filter in filters"
      :key="filter.id"
      class="d-flex align-center mb-2"
    >
      <Operator v-bind="$props" :filter="filter" />
      <Column v-bind="$props" :filter="filter" />
      <Condition v-bind="$props" :filter="filter" />
      <Value v-bind="$props" :filter="filter" />

      <v-btn icon x-small @click="$emit('deleteFilter', filter.id)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </v-card-text>
</template>

<style lang="scss" scoped>
.v-card__text {
  padding-top: 10px;
  max-height: 288px;
  overflow: hidden;
  overflow-y: auto;
}
</style>

<script>
import Operator from "./Operator.vue";
import Column from "./Column.vue";
import Condition from "./Condition.vue";
import Value from "./Value.vue";
export default {
  components: {
    Operator,
    Column,
    Condition,
    Value
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    filter: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
