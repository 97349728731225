var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mr-2",style:(_vm.filterId == 0 ? 'width: 196px;' : 'width: 150px;')},[_c('v-select',{ref:"columnref",attrs:{"dense":"","outlined":"","hide-details":"","return-object":"","label":"컬럼","items":_vm.header.filter(function (h) { return h.type !== 'ADDITIONAL'; }),"item-text":"title","item-value":"title","menu-props":{
      offsetY: true,
      closeOnClick: true,
      maxWidth: 300,
      contentClass: 'cr-modal-close-prevent'
    }},on:{"change":function($event){_vm.filter.condition = {};
      _vm.filter.values = [];
      _vm.SET_FILTERS([].concat( _vm.filters.map(function (f) { return (Object.assign({}, f)); }) ));},"click:append":function($event){$event.stopPropagation();return _vm.$refs.columnref.activateMenu()}},model:{value:(_vm.vModel),callback:function ($$v) {_vm.vModel=$$v},expression:"vModel"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }