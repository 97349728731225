<template>
  <v-container>
    <v-row>
      <!-- 아바타 & 컬러픽커 -->
      <v-col cols="12" class="d-flex justify-center py-4 cr-avatar-wrapper">
        <Avatar
          nudgeTop="-10"
          nudgeLeft="115"
          :workspace="workspace"
          @click:color="clickColor"
          @click:emoji="clickEmoji"
        />
      </v-col>
      <!-- 워크스페이스 이름 -->
      <v-col cols="12" class="pb-0">워크스페이스 이름*</v-col>
      <v-col cols="12" class="pt-0">
        <v-text-field
          ref="newTitle"
          autocomplete="off"
          dense
          required
          autofocus
          hide-details
          v-model="title"
          v-on:keyup.enter="() => $emit('update:confirm', true)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.cr-avatar-wrapper::v-deep {
  .cr-workspace-avatar-emoji {
    span {
      font-size: 70px !important;
    }
  }
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { invertColor } from "@/commons/utils/invertColor";
import { isBlank } from "@/commons/utils/validation";
import Avatar from "../workspace/Avatar.vue";

export default {
  components: { Avatar },
  props: {
    confirm: {
      default: false,
      type: Boolean
    }
  },
  data({ $store }) {
    const { getters } = $store;
    const {
      workspace = {
        emoji: "",
        title: "새로운 워크스페이스",
        color: "#b71c1c",
        avatar: "새로"
      }
    } = getters["todoDialog/params"];

    return {
      tab: 0,
      workspace: { ...workspace },
      title: workspace.title
    };
  },
  computed: {
    ...mapGetters("todoDialog", ["params"])
  },
  watch: {
    confirm(next) {
      if (next) this.addWorkspaceFunc();
    },
    title(v) {
      const { emoji } = this.workspace;
      const avatarText = v.slice(0, 2);
      if (isBlank(emoji) && !isBlank(avatarText)) {
        this.workspace.avatar = avatarText;
      }
    }
  },
  methods: {
    ...mapMutations("todoDialog", ["CLOSE_DIALOG"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("todoWorkspace", ["addWorkspace", "updateWorkspace"]),
    invertColor(color) {
      return invertColor(color);
    },
    clickColor(color) {
      this.workspace.color = color;
    },
    clickEmoji(emoji) {
      let avatarText = this.title.slice(0, 2);
      if (isBlank(avatarText)) avatarText = "새로";
      this.workspace.emoji = emoji;
      this.workspace.avatar = isBlank(emoji) ? avatarText : emoji;
    },
    // 중복 방지 위해 엔터와 확인 버튼 둘다 confirm변경
    async addWorkspaceFunc() {
      const { id: workspaceId, color, emoji } = this.workspace;
      this.title = this.title.trim();

      if (!this.title) {
        this.openSnackbar({
          message: "워크스페이스 이름은 필수값입니다.",
          type: "VALIDATION"
        });
        this.$emit("update:confirm", false);
        return this.$refs.newTitle.focus();
      }

      const params = { title: this.title, color, emoji, useEmoji: !!emoji };
      if (workspaceId > 0) {
        this.updateWorkspace({ workspaceId, ...params });
      } else {
        this.addWorkspace(params);
      }
      this.CLOSE_DIALOG();
    }
  }
};
</script>
