<template>
  <div>
    <div
      v-if="fileCnt > 0"
      class="d-flex align-center justify-space-between flex-wrap cr-attach-label pb-2"
    >
      <div class="d-flex align-center grey--text text--darken-2">
        <span>업로드한 파일</span>
        <span class="text-caption mx-1">{{ `(${fileCnt})` }}</span>
        <v-btn icon x-small @click="showAttachList = !showAttachList">
          <v-icon size="16">
            {{ showAttachList ? "mdi-chevron-down" : "mdi-chevron-right" }}
          </v-icon>
        </v-btn>
      </div>
      <v-btn
        small
        depressed
        outlined
        color="primary"
        @click="download({}, true)"
      >
        모두 다운로드
      </v-btn>
    </div>

    <v-list v-if="fileCnt > 0" v-show="showAttachList" outlined>
      <v-list-item dense v-for="(file, idx) in attachs" :key="idx">
        <v-list-item-content>
          <div
            style="min-width: 0px; max-height: 38px;"
            class="d-flex align-center justify-space-between text-body-2"
          >
            <div
              style="min-width: 0px; max-height: 38px;"
              class="d-flex align-center"
            >
              <v-icon :color="getIcon(file.ext).color">
                {{ getIcon(file.ext).icon }}
              </v-icon>
              <div style="min-width: 0px; max-height: 38px;">
                <div class="d-flex align-center mx-1">
                  <span class="text-truncate">
                    {{ file.name }}
                  </span>
                  <span style="flex-shrink: 0;" class="mr-2">
                    .{{ file.ext }}
                  </span>
                </div>
                <div class="text-caption grey--text ml-1">
                  {{ byteCalculation(file.size) }}
                </div>
              </div>
            </div>

            <!-- 다운로드 삭제버튼 -->
            <div
              v-if="file.status !== 0 && file.status !== 3"
              style="min-width: 56px;"
            >
              <v-btn icon small @click="download(file)">
                <v-icon size="20">mdi-download</v-icon>
              </v-btn>

              <v-btn icon small @click="deleteFileFunc(file)">
                <v-icon size="20">mdi-close</v-icon>
              </v-btn>
            </div>
            <div v-else style="min-width: 200px;" class="d-flex align-center">
              <v-progress-linear
                :value="file.progress"
                height="24"
                :color="file.status === 3 ? 'red' : 'orange'"
                :style="file.status === 3 ? 'color: #fff;' : ''"
              >
                <strong>{{
                  file.status === 3 ? "업로드 실패" : `${file.progress}%`
                }}</strong>
              </v-progress-linear>
              <!-- <v-btn icon small class="ml-1" @click="cancel(file)">
                <v-icon size="20">mdi-close</v-icon>
              </v-btn> -->
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<style lang="scss" scoped>
.cr-attach-label {
  font-size: 14px;
}

.v-list {
  max-height: 342px;
  overflow-y: auto;
  .v-list-item {
    padding: 0px 5px;
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { fileIcons, fileIconColors } from "@/commons/utils/icons";
import { byteCalculation } from "@/commons/utils/byteCalculation";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { showAttachList: true };
  },
  computed: {
    ...mapGetters("todoDetail", ["files", "cancelTokenSources"]),
    ...mapGetters("todoHeader", ["header"]),
    attachs() {
      return this.files.map(i => {
        const ext = i.name
          .split("?")[0]
          .split("#")[0]
          .split(".")
          .pop();
        const name = i.name.slice(0, i.name.length - 1 - ext.length);
        return { ...i, fullName: i.name, name, ext };
      });
    },
    fileCnt() {
      return this.attachs.length;
    }
  },
  watch: {
    files({ length }) {
      this.showAttachList = length > 0;
      const {
        item: { id: itemId, parentId, groupId }
      } = this;
      this.SET_ITEM_ATTRS({
        itemId,
        groupId,
        parentId,
        keyValues: [{ key: "attachCnt", value: length }]
      });
    }
  },
  methods: {
    ...mapMutations("todoItem", ["SET_ITEM_ATTRS"]),
    ...mapActions("auth", ["checkToken"]),
    ...mapActions("todoDetail", ["deleteFile"]),
    ...mapActions("confirm", ["confirm", "disagree", "agree"]),
    byteCalculation(size) {
      return byteCalculation(size);
    },
    getIcon(name) {
      const index = name.lastIndexOf(".");
      const ext = name.substring(index + 1, name.length).toLowerCase();

      return {
        icon: fileIcons[ext] || fileIcons["file"],
        color: fileIconColors[ext] || fileIconColors["file"]
      };
    },
    cancel(file) {
      if (!file.key || !this.cancelTokenSources[file.key]) return;
      this.cancelTokenSources[file.key].cancel("cancel");
    },
    async download({ id }, isAll) {
      await this.checkToken();

      const access_token = localStorage.getItem("access_token");
      let url = `${process.env.VUE_APP_API_SERVER_URL}/api/todo/file/${id}/download?access_token=${access_token}`;
      if (isAll) {
        const [{ value }] = this.header.filter(h => h.type === "TITLE");
        const { id: itemId, [value]: title } = this.item;
        url = `${process.env.VUE_APP_API_SERVER_URL}/api/todo/file/download/${itemId}?access_token=${access_token}&title=${title}`;
      }

      window.open(url);
    },
    deleteFileFunc({ id, fullName }) {
      this.confirm({
        message: fullName + " 파일을 삭제하시겠습니까?",
        callback: () => {
          const { id: itemId, boardId } = this.item;
          this.deleteFile({ itemId, boardId, id });
        }
      });
    }
  }
};
</script>
