<template>
  <div class="cr-item-detail-attach-wrapper">
    <div class="d-flex align-center text-subtitle-1 font-weight-bold py-2">
      <span>첨부자료</span>
      <v-btn
        icon
        outlined
        x-small
        color="primary"
        class="ml-2"
        @click="openFileInput"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>

    <AttachList :item="item" />
    <input
      multiple
      v-show="false"
      type="file"
      ref="fileInput"
      @change="changeFileInput"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-item-detail-attach-wrapper {
  padding: 16px 23px;
}
</style>

<script>
import { mapActions } from "vuex";
import AttachList from "./AttachList.vue";

export default {
  components: {
    AttachList
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    ...mapActions("todoDetail", ["setFiles"]),
    openFileInput() {
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
    changeFileInput(e) {
      const files = e.target.files;
      if (!files) return;

      const {
        item: { id: itemId, boardId }
      } = this;
      this.setFiles({ itemId, boardId, files });
    }
  }
};
</script>
