<template>
  <v-hover v-slot="{ hover }">
    <v-tab @click="click">
      <div class="d-flex px-2">
        <PinBtn v-bind="$props" :hover="hover" />
        <span>{{ filter.filterName }}</span>
        <EditBtn v-bind="$props" :hover="hover" />
      </div>
    </v-tab>
  </v-hover>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PinBtn from "./PinBtn.vue";
import EditBtn from "./EditBtn.vue";

export default {
  components: { PinBtn, EditBtn },
  props: {
    filter: {
      type: Object,
      default: () => ({})
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("todoRoute", ["routeState"])
  },
  methods: {
    ...mapActions("todoRoute", ["routeBoardAction"]),
    click() {
      const { id: filterId } = this.filter;
      const { workspaceId, boardId, viewType } = this.routeState;
      const actionObj = JSON.stringify({ filterId, viewType });

      this.routeBoardAction({ workspaceId, boardId, actionObj });
    }
  }
};
</script>
