<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          dense
          chips
          clearable
          deletable-chips
          multiple
          small-chips
          outlined
          hide-details
          return-object
          ref="columnref"
          label="숫자컬럼"
          item-text="title"
          item-value="value"
          :disabled="loading"
          :loading="loading"
          :menu-props="{
            'offset-y': true,
            closeOnClick: true
          }"
          :items="numberColumns"
          v-model="selectedColumn"
          @change="changeColumn"
        >
          <template v-slot:selection="{ item }">
            <v-chip
              v-if="item.type !== 'add'"
              small
              close
              label
              @click:close="deleteChip(item)"
            >
              <div class="text-truncate" style="max-width: 120px;">
                <span>{{ item.title }}</span>
              </div>
            </v-chip>
          </template>
          <template v-slot:item="{ item }">
            <div v-if="item.type == 'add'">
              <v-icon>mdi-plus</v-icon> 새로운 컬럼
            </div>
            <div v-else>
              {{ item.title }}
            </div>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import headerUtils from "@/todo/store/utils/header";

export default {
  props: {
    confirm: {
      default: false,
      type: Boolean
    }
  },
  data({ $store }) {
    const { headerValue } = $store.getters["todoDialog/params"] ?? {};
    const header = $store.getters["todoHeader/header"];
    const [timeline] = header.filter(h => h.value == headerValue);
    let selectedColumn = [];
    if (timeline) {
      selectedColumn = [
        ...header
          .filter(h => timeline.connectedColumn?.includes(h.value))
          .map(h => ({ ...h }))
      ];
    }

    return {
      selectedColumn,
      loading: false
    };
  },
  computed: {
    ...mapGetters("todoDialog", ["params"]),
    ...mapGetters("todoHeader", ["header"]),
    numberColumns() {
      return [
        ...this.header
          .filter(h => h.type == headerUtils.NUMBER().type)
          .map(h => ({ ...h })),
        { type: "add", title: "", value: "add-column" }
      ];
    }
  },
  watch: {
    confirm(next) {
      if (next) {
        this.connectColumn();
      }
    }
  },
  methods: {
    ...mapMutations("todoDialog", ["CLOSE_DIALOG"]),
    ...mapMutations("todoHeader", ["DISCONNECT_HEADER"]),
    ...mapActions("todoHeader", ["updateHeader"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    deleteChip({ value }) {
      this.selectedColumn = this.selectedColumn.filter(h => h.value !== value);
    },
    changeColumn(column) {
      const [addColumn] = column.filter(c => c.type == "add");
      if (addColumn) {
        this.$nextTick(async () => {
          this.selectedColumn = column.filter(c => c.type !== "add");
          if (this.selectedColumn.length >= 2) {
            this.openSnackbar({
              message: "숫자 컬럼은 최대 2개까지 연결할 수 있습니다.",
              type: "VALIDATION"
            });
            return;
          }
          this.$refs.columnref.blur();

          this.loading = true;
          await this.updateHeader({
            mutation: "ADD_HEADER",
            boardId: this.params.boardId,
            params: {
              index: this.header.length - 1,
              header: {
                ...headerUtils.NUMBER(),
                title: "숫자",
                labels: [],
                format: "default"
              }
            }
          });
          this.selectedColumn.push(this.numberColumns.at(-2));
          this.loading = false;
        });
      }

      if (column.length > 2) {
        this.$nextTick(() => this.selectedColumn.splice(column.length - 1, 1));
        this.openSnackbar({
          message: "숫자 컬럼은 최대 2개까지 연결할 수 있습니다.",
          type: "VALIDATION"
        });
        return;
      }
    },
    // 중복 방지 위해 엔터와 확인 버튼 둘다 confirm변경
    async connectColumn() {
      const { params } = this;
      switch (this.selectedColumn.length) {
        case 0:
        case 1:
        case 2:
          this.DISCONNECT_HEADER({ timelineValue: params.headerValue });
          this.updateHeader({
            mutation: "CONNECT_HEADER",
            boardId: params.boardId,
            params: {
              timelineValue: params.headerValue,
              numberValues: this.selectedColumn.map(c => c.value).join(",")
            }
          });
          this.CLOSE_DIALOG();
          return;
        default:
          this.openSnackbar({
            message: "숫자 컬럼은 최대 2개까지 연결할 수 있습니다.",
            type: "VALIDATION"
          });
          this.$emit("update:confirm", false);
          return;
      }
    }
  }
};
</script>
