<template>
  <div class="cr-todo-filter-content">
    <Title :filterName.sync="filterName" :isDefault.sync="isDefault" />
    <Conditions
      :filters="filters"
      class="d-flex align-center mb-2"
      @deleteFilter="deleteFilter"
    />
    <v-btn text outlined class="ml-4 mt-3" @click="addFilter">
      + 새로운 필터 추가
    </v-btn>
    <Actions :filterName="filterName" :isDefault="isDefault" />
  </div>
</template>

<style>
.cr-todo-filter ::-webkit-scrollbar {
  height: 10px;
}
</style>
<style lang="scss" scoped>
.cr-todo-filter-content {
  width: 745px;
  display: contents;
}
</style>

<script>
import { mapMutations } from "vuex";
import Title from "./filter/title";
import Conditions from "./filter/conditions";
import Actions from "./filter/actions";
const defaultFilter = {
  id: 0,
  column: {},
  condition: {},
  values: [],
  operator: "where"
};

export default {
  components: { Title, Conditions, Actions },
  data({ $store }) {
    const { getters } = $store;
    const { filterId, type } = getters["todoModal/params"];
    const currentFilters = getters["todoFilter/filters"];

    let filterName = "새로운 필터";
    let isDefault = 0;
    let filters = [{ ...defaultFilter }];
    // 현재 적용중인 필터확인
    if (currentFilters.length > 0) {
      filters = [...currentFilters.map(f => ({ ...f }))];
      return { filterName, isDefault, filters };
    }

    // 저장된 필터 수정
    if (type == "update") {
      const savedFilters = getters["todoFilter/savedFilters"];
      const [filter] = savedFilters.filter(f => f.id == filterId);
      if (filter) {
        filterName = filter.filterName;
        isDefault = filter.id === getters["todoFilter/defaultFilterId"] ? 1 : 0;
        filters = [...filter.filters.map(f => ({ ...f }))];
        return { filterName, isDefault, filters };
      }
    }

    return { filterName, isDefault, filters };
  },
  methods: {
    ...mapMutations("todoModal", ["CLOSE_MODAL"]),
    ...mapMutations("todoFilter", ["SET_FILTERS"]),
    addFilter() {
      const { id } = this.filters.at(-1);
      this.filters.push({ ...defaultFilter, id: id + 1, operator: "and" });
    },
    deleteFilter(id) {
      const index = this.filters.findIndex(f => f.id == id);
      if (index === 0) {
        this.filters.splice(index, 1, { ...defaultFilter });
      } else {
        this.filters.splice(index, 1);
      }

      this.SET_FILTERS([...this.filters.map(f => ({ ...f }))]);
    },
    clickOutside(e) {
      if (
        e.target.closest(".cr-todo-filter") ||
        e.target.closest(".cr-todo-filter--")
      ) {
        return;
      }

      this.CLOSE_MODAL();
    }
  }
};
</script>
