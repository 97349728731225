<template>
  <v-list-item dense>
    <v-list-item-avatar>
      <div
        class="d-flex align-center justify-center font-weight-bold"
        style="width:100%; height:100%; background: rgb(11, 41, 111); color: #fff;"
      >
        {{ avatarTxt }}
      </div>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title class="py-2">
        <span class="font-weight-black" style="font-size: 16px;">
          {{ comment.authorName }}
        </span>
        <span class="ml-2" style="font-size: 12px;">
          {{ getFullDate }}
        </span>
      </v-list-item-title>
      <v-list-item-subtitle v-if="!comment.edit">
        {{ comment.content }}
      </v-list-item-subtitle>
      <div v-else>
        <v-textarea
          ref="textRef"
          dense
          hide-details
          hide-spin-buttons
          no-resize
          outlined
          auto-grow
          autofocus
          rows="1"
          :loading="loading"
          :disabled="loading"
          v-model="text"
          @blur="blur"
          @focus="$emit('update:editable', true)"
          @keydown.esc="blur"
        />
        <div class="d-flex justify-end mt-3">
          <v-btn depressed :disabled="loading">취소</v-btn>
          <v-btn
            depressed
            color="primary"
            class="ml-2"
            :disabled="loading || updateBtnDisabled"
            @click.capture.stop="updateCommentFunc"
          >
            저장
          </v-btn>
        </div>
      </div>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn
        v-if="!comment.edit && getUserInfo.id === comment.authorId"
        icon
        class="cr-menu-close-prevent"
        :class="
          type === 'comment' && subType === comment.id ? 'cr-menu-open' : ''
        "
        @click="openMenu"
      >
        <v-icon size="16" color="grey lighten-1">mdi-dots-vertical</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<style lang="scss" scoped>
.v-list {
  .v-list-item {
    padding: 8px 16px;
    .v-avatar {
      align-self: flex-start;
    }
    .v-list-item__content {
      padding: 3px 0px;
      user-select: text;
    }
    .v-list-item__subtitle {
      white-space: pre-line;
      font-size: 0.875rem;
      line-height: 1.4;
      color: rgba(0, 0, 0, 0.87);
    }
    .v-list-item__action {
      min-width: 36px;
      margin: 2px 0px 0px;
      align-self: flex-start;
      .v-btn {
        display: none;
        &.cr-menu-open {
          display: inline-flex;
        }
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 0.05);

      .v-list-item__action {
        .v-btn {
          display: inline-flex;
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getFullDate } from "@/commons/utils/moment";
import { isBlank } from "@/commons/utils/validation";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    comment: {
      type: Object,
      default: () => ({})
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data({ comment }) {
    return {
      text: comment?.content ?? "",
      clickedBtn: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("todoRoute", ["viewType"]),
    ...mapGetters("todoMenu", ["type", "subType"]),
    ...mapGetters("sidePanel", { sidePanelWidth: "renderedWidth" }),
    avatarTxt() {
      if (this.comment?.authorName) {
        return this.comment.authorName.slice(0, 1);
      }
      return "";
    },
    getFullDate() {
      if (this.comment?.authorTimeMillis) {
        return getFullDate(this.comment.authorTimeMillis);
      }

      return "";
    },
    updateBtnDisabled() {
      return this.comment.content === this.text;
    }
  },
  methods: {
    ...mapMutations("todoDetail", ["SET_COMMENT_EDIT"]),
    ...mapActions("todoMenu", ["setMenu"]),
    ...mapActions("todoDetail", ["updateComment"]),
    openMenu(e) {
      const { top, left, height, width } = e.target.getBoundingClientRect();
      const { offsetWidth: rootWidth } = this.$root.$el;
      const { offsetWidth: listWidth } = document.getElementById("todoList");
      const appbarHeight = 64;
      const toolbarHeight = this.viewType === "gantt" ? 106 : 66;
      const naviWidth = rootWidth - listWidth;

      this.setMenu({
        type: "comment",
        subType: this.comment.id,
        itemId: this.item.id,
        parentId: this.item.parentId,
        boardId: this.item.boardId,
        headerValue: "comment_" + this.comment.id,
        top: top + height - appbarHeight - toolbarHeight,
        left: left - naviWidth + this.sidePanelWidth,
        targetWidth: width,
        targetHeight: height,
        isDetail: true
      });
    },
    blur() {
      setTimeout(() => {
        if (this.clickedBtn) return;
        this.cancelEdit();
      }, 200);
    },
    cancelEdit() {
      if (!this.$refs.textRef) return;

      this.$refs.textRef.reset();
      this.text = this.comment.content;
      this.SET_COMMENT_EDIT({ id: this.comment.id, edit: false });
      this.$emit("update:editable", false);
    },
    async updateCommentFunc() {
      this.clickedBtn = true;
      if (isBlank(this.text.trim())) {
        this.$refs.textRef.focus();
        return;
      }

      const {
        item: { id: itemId, boardId },
        comment: { id }
      } = this;
      this.loading = true;
      await this.updateComment({
        itemId,
        boardId,
        id,
        content: this.text.trim()
      });
      this.clickedBtn = false;
      this.loading = false;
      this.$emit("update:editable", false);
    }
  }
};
</script>
