<template>
  <div
    class="cr-item-created-log"
    :class="{
      'cr-tooltip': !isDetail,
      'cr-item-detail': isDetail,
      'cr-item-kanban': isKanban
    }"
    :title="`${item.ownerName} / ${getFullDate(item.createdTimeMillis)}`"
  >
    <span>
      {{ item.ownerName }} / {{ getFullDate(item.createdTimeMillis) }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.cr-item-created-log {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0px;
  font-size: 14px;
  padding: 4px 5px;
  margin: 0px 8px;
}
</style>

<script>
import { getFullDate } from "@/commons/utils/moment";

export default {
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    header: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    isKanban: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getFullDate(createdTimeMillis) {
      return getFullDate(createdTimeMillis);
    }
  }
};
</script>
