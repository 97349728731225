<template>
  <div
    class="cr-item-link-wrapper"
    :class="{ 'cr-item-detail': isDetail, 'cr-item-kanban': isKanban }"
    :title="inputValue"
  >
    <div
      v-if="isNotEmptyItem && !editable"
      class="cr-item-link"
      :class="`${dragMode ? 'cr-item-drag-mode' : ''}`"
      @click.stop="changeEditable"
    >
      <span class="cr-link" @click.stop="openLink">
        {{ inputValue }}
      </span>
      <v-icon
        v-if="!inputValue && inputValue !== 0"
        class="cr-link-icon"
        :size="iconSize"
      >
        mdi-link-variant-plus
      </v-icon>
    </div>

    <v-text-field
      v-else
      ref="textRef"
      dense
      hide-details
      outlined
      :loading="loading"
      :disabled="loading"
      :value="inputValue"
      @focus="inputFocus"
      @blur="inputBlur"
      @keydown.esc="$refs.textRef.blur()"
      @keydown.enter.exact.prevent="add"
    ></v-text-field>
  </div>
</template>

<style lang="scss" scoped>
.cr-item-link-wrapper {
  height: 100%;
  min-width: 0px;
  padding: 4px 2px;

  .cr-item-link {
    cursor: text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    min-width: 0px;
    font-size: 16px;
    margin: 0px 4px;
    line-height: 27px;
    color: #424242;
    border: thin solid transparent;
    padding-left: 3px;

    .cr-link {
      color: var(--v-anchor-base);
      cursor: pointer;
    }
    .cr-link-icon {
      display: none;
      margin-bottom: 2px;
      margin-left: 4px;
    }

    &:hover {
      border-color: rgba(0, 0, 0, 0.12);
      .cr-link-icon {
        display: inline-flex;
      }
    }
    &.cr-item-drag-mode:hover {
      border-color: transparent;
      .cr-link-icon {
        display: none;
      }
    }
  }

  .v-input.v-text-field::v-deep {
    .v-input__control {
      .v-input__slot {
        min-height: 34px;
        padding: 0 9px;
        fieldset {
          border: none;
        }
        .v-text-field__slot {
          input {
            padding: 7px 0 9px;
          }
        }
      }
    }
  }

  &.cr-item-detail {
    padding: 5px 0px;
    .cr-item-link {
      text-align: center;
      min-height: 40px;
      border: thin solid transparent;
      margin: 0px;
      padding: 1px;
      line-height: 38px;
      white-space: pre-line;
      &:hover {
        border-color: rgba(0, 0, 0, 0.12);
      }
      .cr-link-icon {
        display: inline;
      }
    }

    .v-input.v-text-field::v-deep {
      margin: 1px 0px;
      .v-input__control {
        .v-input__slot {
          padding: 0px 4px;
          min-height: 40px;
          fieldset {
            border: thin solid rgba(0, 0, 0, 0.38);
          }
          input {
            line-height: 38px;
            margin: 0px;
            padding: 1px 0px;
            min-height: 40px;
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    header: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    },
    TABLE_TD_HEIGHT: {
      type: Number,
      default: 48
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    isKanban: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { loading: false, editable: false };
  },
  computed: {
    ...mapGetters("dragDrop", ["dragMode"]),
    iconSize() {
      switch (this.TABLE_TD_HEIGHT) {
        case 38:
          return 20;
        default:
          return 24;
      }
    },
    isNotEmptyItem() {
      return (
        this.item?.constructor === Object && Object.keys(this.item).length > 0
      );
    },
    inputValue() {
      if (this.isNotEmptyItem) {
        return this.item[this.header.value];
      }

      return "";
    }
  },
  methods: {
    ...mapActions("todoItem", ["updateItem"]),
    changeEditable() {
      this.editable = true;
      this.$nextTick(() => {
        this.$refs.textRef.focus();
      });
    },
    inputFocus() {
      if (this.isDetail || this.isKanban) return;
      this.$el.parentNode.style.border = "1px solid var(--v-primary-base)";
      this.$el.style.paddingLeft = "0px";
      this.$el.style.paddingTop = "2px";
      this.$el.style.paddingBottom = "0px";
    },
    inputBlur() {
      this.$el.parentNode.style.border = "";
      this.$el.style.paddingLeft = "";
      this.$el.style.paddingTop = "";
      this.$el.style.paddingBottom = "";
      this.editable = false;
      this.$refs.textRef?.reset();
    },
    async add(e) {
      let text = e.target.value.trim();
      if (this.inputValue == text) {
        this.$refs.textRef.blur();
        return;
      }

      if (!text.startsWith("http")) {
        text = "https://" + text;
      }

      const { id: groupId, boardId } = this.group;
      const { id: itemId, parentId } = this.item ?? {};
      const { value: headerValue } = this.header;
      this.loading = true;

      await this.updateItem({
        boardId,
        groupId,
        itemId,
        parentId,
        headerValue,
        itemValue: text,
        prevValue: this.inputValue
      });

      this.loading = false;
      if (this.$refs.textRef) {
        this.$refs.textRef.blur();
      }
    },
    openLink() {
      window.open(this.inputValue);
    }
  }
};
</script>
