<template>
  <v-btn v-if="show" tile text @click="changeViewType">
    {{ title }}
    <v-badge v-if="isKanban" content="Alpha" inline small color="red"></v-badge>
  </v-btn>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("auth", ["getPlanType", "getUserInfo"]),
    ...mapGetters("todoRoute", ["routeState"]),
    isKanban() {
      const { type } = this.item;
      if (type === "kanban") return true;
      return false;
    },
    show() {
      const { type } = this.item;
      if (this.getPlanType !== "ADVANCED") {
        if (type === "gantt") return false;
        if (type === "kanban") return false;
      }

      // TODO 크리니티만
      if (type === "kanban" && this.getUserInfo.companyId !== 1) {
        return false;
      }
      return true;
    },
    title() {
      return this.item?.title || "";
    }
  },
  methods: {
    ...mapActions("todoRoute", ["routeBoardAction"]),
    changeViewType() {
      const { type: viewType } = this.item;
      const { workspaceId, boardId, filterId } = this.routeState;
      const actionObj = JSON.stringify({ filterId, viewType });

      this.routeBoardAction({ workspaceId, boardId, actionObj });
    }
  }
};
</script>
