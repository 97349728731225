<template>
  <v-btn
    icon
    x-small
    :class="subBtnCls"
    :loading="loading"
    @click="click"
    @mouseover="e => setTooltip(e, subBtnMsg.t)"
    @mouseout="HIDE_TOOLTIP"
  >
    <v-icon size="20" :color="color">{{ subBtnMsg.i }}</v-icon>
  </v-btn>
</template>

<style lang="scss" scoped>
.v-btn.cr-sub-btn::v-deep {
  position: absolute;
  display: none;
  top: calc(50% - 10px);
  left: 3px;

  &.cr-add-btn {
    left: 46px;
  }

  &.cr-exist-sub {
    display: unset;
    .v-btn__loader {
      .v-progress-circular {
        height: 16px !important;
        width: 16px !important;
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters("todoItem", ["showSubItems"]),
    color() {
      const { subCnt, parentId } = this.item;
      if (subCnt > 0 || parentId > 0) return "";
      return "grey lighten-1";
    },
    showSub() {
      return this.showSubItems[this.item.id];
    },
    subBtnCls() {
      const { subCnt, parentId } = this.item;
      return {
        "cr-sub-btn cr-tooltip": true,
        "cr-exist-sub": (subCnt && !parentId) || this.loading,
        "cr-add-btn": parentId
      };
    },
    subBtnMsg() {
      const { parentId } = this.item;

      if (parentId) return { t: "아래에 아이템 추가", i: "mdi-plus" };
      if (this.showSub) return { t: "하위 아이템 축소", i: "mdi-chevron-down" };
      return { t: "하위 아이템 확장", i: "mdi-chevron-right" };
    }
  },
  methods: {
    ...mapMutations("todoTooltip", ["SET_TOOLTIP", "HIDE_TOOLTIP"]),
    ...mapMutations("todoItem", ["CLOSE_SUB_ITEMS"]),
    ...mapActions("todoItem", ["getSubItems", "addItem"]),
    setTooltip(event, msg) {
      this.SET_TOOLTIP({ msg, position: "top", event });
    },
    async click() {
      const { id: groupId } = this.group;
      const { id, parentId, subCnt } = this.item;

      // 열려있을때 (닫기)
      if (this.showSub) return this.CLOSE_SUB_ITEMS({ groupId, parentId: id });
      // 하위아이템 없거나, 아래에 새 아이템 추가
      if (!subCnt || parentId) return this.add();
      // 하위아이템 있을때 (열기)
      if (subCnt > 0) {
        this.loading = true;

        await this.getSubItems({ groupId, parentId: id });
        this.loading = false;
      }
    },
    async add() {
      this.loading = true;
      const { id: groupId, boardId } = this.group;
      const { id, parentId: pId, subCnt, sortOrder: so } = this.item;

      const title = "하위 아이템";
      const parentId = !subCnt && !pId ? id : pId;
      const sortOrder = !subCnt && !pId ? 0 : so + 1;
      const params = { boardId, groupId, parentId, sortOrder, title };

      await this.addItem(params);
      this.loading = false;
    }
  }
};
</script>
