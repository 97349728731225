<template>
  <div
    class="cr-status-wrapper cr-menu-close-prevent"
    :class="{ 'cr-item-detail': isDetail, 'cr-item-kanban': isKanban }"
    :style="`background-color: ${itemValue.color};`"
    @click="openMenu"
  >
    <span
      class="text-button text-truncate"
      :style="`color: ${invertColor(itemValue.color)};`"
    >
      {{ itemValue.title }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.cr-status-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0px 10px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.cr-status-wrapper:hover::before {
  content: "";
  position: absolute;
  background-color: currentColor;
  opacity: 0.1;
  width: 100%;
  height: 100%;
  left: 0px;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { invertColor } from "@/commons/utils/invertColor";

export default {
  props: {
    header: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    isKanban: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("todoRoute", ["viewType"]),
    ...mapGetters("sidePanel", { sidePanelWidth: "renderedWidth" }),
    itemValue() {
      const { labels, value: headerValue } = this.header;
      const [statusLabel] = labels.filter(
        ({ value }) => value == this.item[headerValue]
      );
      const defaultLabel = {
        title: "",
        color: "#f2f2f2",
        defaultLabel: true,
        value: `default_${new Date().getTime()}`
      };

      return statusLabel ?? defaultLabel;
    }
  },
  methods: {
    ...mapActions("todoMenu", ["setMenu"]),
    invertColor(color) {
      return invertColor(color);
    },
    openMenu(e) {
      const { top, left, height, width } = e.target.getBoundingClientRect();
      const { offsetWidth: rootWidth } = this.$root.$el;
      const { offsetWidth: listWidth } = document.getElementById("todoList");
      const appbarHeight = 64;
      const toolbarHeight = this.viewType === "gantt" ? 116 : 76;
      const naviWidth = rootWidth - listWidth;
      const { id: itemId, parentId, boardId, groupId } = this.item;

      this.setMenu({
        type: "label",
        itemId,
        parentId,
        groupId,
        boardId,
        itemValue: this.itemValue,
        headerValue: this.header.value,
        top: top + height - appbarHeight - toolbarHeight,
        left: left - naviWidth + this.sidePanelWidth,
        targetWidth: width,
        targetHeight: height
      });
    }
  }
};
</script>
