import headerUtils from "@/todo/store/utils/header";
const key = "crm_3";

export default {
  /**
   * headerUtils.TITLE, headerUtils.ADDITIONAL 두개는 필수 값 (정해진 순서대로)
   */
  genHeader: () => [
    { ...headerUtils.TITLE(), value: key + "_title" },
    {
      ...headerUtils.STATUS(),
      value: key + "_status_2",
      title: "todo.CRM#3_버전",
      width: 110,
      labels: [
        {
          title: "todo.CRM#3_2021",
          value: `${key}_status_2_1`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#3_2019",
          value: `${key}_status_2_2`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#3_2016",
          value: `${key}_status_2_3`,
          color: "#FFFFFF"
        }
      ]
    },
    {
      ...headerUtils.NUMBER(),
      value: key + "_number_1",
      title: "todo.CRM#3_단가",
      width: 120
    },
    {
      ...headerUtils.TEXT(),
      value: key + "_desc_1",
      title: "todo.CRM#3_비고",
      width: 120
    },
    {
      ...headerUtils.STATUS(),
      value: key + "_status_1",
      title: "todo.CRM#3_월",
      width: 110,
      labels: [
        {
          title: "todo.CRM#3_1월",
          value: `${key}_status_1_1`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#3_2월",
          value: `${key}_status_1_2`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#3_3월",
          value: `${key}_status_1_3`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#3_4월",
          value: `${key}_status_1_4`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#3_5월",
          value: `${key}_status_1_5`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#3_6월",
          value: `${key}_status_1_6`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#3_7월",
          value: `${key}_status_1_7`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#3_8월",
          value: `${key}_status_1_8`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#3_9월",
          value: `${key}_status_1_9`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#3_10월",
          value: `${key}_status_1_10`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#3_11월",
          value: `${key}_status_1_11`,
          color: "#FFFFFF"
        },
        {
          title: "todo.CRM#3_12월",
          value: `${key}_status_1_12`,
          color: "#FFFFFF"
        }
      ]
    },
    {
      ...headerUtils.NUMBER(),
      value: key + "_number_2",
      title: "todo.CRM#3_목표",
      width: 120
    },
    {
      ...headerUtils.NUMBER(),
      value: key + "_number_3",
      title: "todo.CRM#3_매출액",
      width: 120
    },
    { ...headerUtils.ADDITIONAL() }
  ],
  genGroups: () => [{ title: "todo.CRM#3_매출현황", color: "#424242" }],
  /**
   * genGroups의 size와 genItems 배열안에 배열개수를 맞춰줘야함.
   * ex) groups: [{}, {}] , items: [[{}, {}, {}], []]
   */
  genItems: () => [
    [
      {
        [key + "_title"]: "todo.CRM#3_item-1",
        [key + "_status_2"]: `${key}_status_2_1`,
        [key + "_number_1"]: "50000",
        [key + "_status_1"]: `${key}_status_1_1`,
        [key + "_number_2"]: "350000",
        [key + "_number_3"]: "300000"
      },
      {
        [key + "_title"]: "todo.CRM#3_item-2",
        [key + "_status_2"]: `${key}_status_2_2`,
        [key + "_number_1"]: "20000",
        [key + "_status_1"]: `${key}_status_1_3`,
        [key + "_number_2"]: "0",
        [key + "_number_3"]: "0"
      }
    ]
  ]
};
