<template>
  <div class="cr-select-wrapper cr-drag-prevent">
    <v-checkbox
      v-if="isTitle || !item.parentId"
      hide-details
      ref="check"
      color="gray"
      class="mt-0 pt-0 cr-modal-close-prevent"
      :value="checked"
      :disabled="disabled"
      @click="$emit('check')"
    />

    <!-- 상위 아이템 -->
    <div v-if="!isTitle && showSubItems[item.id]" class="cr-1" />
    <!-- 하위아이템 세로줄 -->
    <div v-if="!isTitle && item.parentId && !item.isLast" class="cr-2" />
    <!-- 하위아이템 가로줄 -->
    <div v-if="!isTitle && item.parentId" class="cr-3" />
    <!-- 마지막 하위아이템 세로줄 -->
    <div v-if="!isTitle && item.isLast" class="cr-4" />
  </div>
</template>

<style lang="scss" scoped>
.cr-select-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 4px;
  margin-left: 5px;

  .v-input.v-input--checkbox::v-deep {
    .v-input--selection-controls__ripple {
      height: 28px;
      width: 30px;
      left: -12px;
      top: calc(50% - 23px);
      margin: 9px;
    }
  }

  // 첫번째 부모아이템
  .cr-1 {
    position: absolute;
    background: var(--group-color);
    height: 3px;
    width: 55%;
    bottom: -1px;
    left: -3px;
  }
  // 하위아이템 세로
  .cr-2 {
    position: absolute;
    background: var(--group-color);
    height: 100%;
    width: 3px;
    top: 0px;
    left: calc(50% - 1.5px);
  }
  // 하위아이템 가로
  .cr-3 {
    position: absolute;
    background: var(--group-color);
    height: 3px;
    width: 50%;
    top: calc(50% - 1.5px);
    left: 24px;
  }
  // 하위아이템 마지막 세로
  .cr-4 {
    position: absolute;
    background: var(--group-color);
    height: 54%;
    width: 3px;
    top: 0px;
    left: calc(50% - 1.5px);
  }
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.disabled = this.getDisabled();

    if (!this.checked || !this.$refs.check) return;
    this.$refs.check.onChange();
  },
  data() {
    return {
      disabled: false
    };
  },
  computed: {
    ...mapGetters("todoItem", ["selectedItems", "showSubItems"]),
    isTitle() {
      return this.$vnode.key === "title";
    }
  },
  watch: {
    selectedItems() {
      this.disabled = this.getDisabled();
    }
  },
  methods: {
    getDisabled() {
      if (this.$vnode.key === "addTr") return true;

      const items = this.selectedItems[this.item.groupId];
      if (!items) return false;
      return !!items.find(i => i.id == this.item.parentId);
    }
  }
};
</script>
