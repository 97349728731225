<template>
  <v-btn-toggle v-model="todoLayout" tile dense small color="primary">
    <Btn v-for="item in viewTypes" :key="item.type" :item="item" />
  </v-btn-toggle>
</template>

<style lang="scss" scoped>
.v-btn-toggle::v-deep {
  background-color: transparent;
  .v-btn {
    border-top-width: 0px;
  }
  .v-btn:first-child {
    border-radius: 0px 0px 0px 8px !important;
  }
  .v-btn:last-child {
    border-right-width: 0px !important;
    border-radius: 0px 8px 0px 0px !important;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import Btn from "./Btn.vue";

export default {
  components: { Btn },
  data({ $store: { getters } }) {
    const viewTypes = getters["todoRoute/viewTypes"];
    const viewType = getters["todoRoute/viewType"];
    const todoLayout = viewTypes.findIndex(v => v.type === viewType);

    return { todoLayout };
  },
  computed: {
    ...mapGetters("todoRoute", ["viewType", "viewTypes"])
  },
  watch: {
    // 앞으로가기 뒤로가기시 변경
    viewType(viewType) {
      this.todoLayout = this.viewTypes.findIndex(v => v.type === viewType);
    },
    // 선택해제방지
    todoLayout(n, p) {
      if (n !== undefined) return;

      setTimeout(() => {
        this.todoLayout = p;
      }, 0);
    }
  }
};
</script>
