<template>
  <v-card-text>
    <AddWorkspace
      v-if="dialogType === 'addWorkspace'"
      v-bind="$props"
      v-on="$listeners"
    />
    <AddBoard
      v-if="dialogType === 'addBoard'"
      v-bind="$props"
      v-on="$listeners"
    />
    <ConnectColumn
      v-if="dialogType === 'connectColumn'"
      v-bind="$props"
      v-on="$listeners"
    />
  </v-card-text>
</template>

<script>
import { mapGetters } from "vuex";
import AddWorkspace from "./AddWorkspace.vue";
import AddBoard from "./AddBoard.vue";
import ConnectColumn from "./ConnectColumn.vue";

export default {
  components: { AddWorkspace, AddBoard, ConnectColumn },
  props: {
    confirm: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters("todoDialog", ["dialogType"])
  }
};
</script>
