<template>
  <v-btn
    v-show="show"
    icon
    x-small
    class="ml-2 cr-tooltip"
    @click="updateDefaultFilterId({ ...filter })"
    @mouseover="event => SET_TOOLTIP({ msg: '기본값으로 설정', event })"
    @mouseout="HIDE_TOOLTIP"
  >
    <v-icon small :color="color">
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<style lang="scss" scoped>
.v-btn {
  position: absolute;
  left: -5px;
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  props: {
    filter: {
      type: Object,
      default: () => ({})
    },
    hover: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("todoFilter", ["defaultFilterId"]),
    show() {
      return (this.hover || this.isDefault) && this.filter.id;
    },
    isDefault() {
      return this.filter.id === this.defaultFilterId;
    },
    icon() {
      return this.isDefault ? "mdi-pin" : "mdi-pin-outline";
    },
    color() {
      return this.isDefault && this.isSelected ? "primary" : "";
    }
  },
  methods: {
    ...mapMutations("todoTooltip", ["SET_TOOLTIP", "HIDE_TOOLTIP"]),
    ...mapActions("todoFilter", ["updateDefaultFilterId"])
  }
};
</script>
