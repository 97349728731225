<template>
  <v-card-actions>
    <v-spacer />
    <v-btn
      v-if="params.filterId > 0"
      outlined
      color="red"
      @click="deleteConfirm"
    >
      {{ "삭제" }}
    </v-btn>
    <v-btn outlined color="accent" @click="save">
      {{ "저장" }}
    </v-btn>
  </v-card-actions>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { isBlank } from "@/commons/utils/validation";

export default {
  props: {
    filterName: {
      type: String,
      default: ""
    },
    isDefault: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters("todoFilter", ["filtersIds", "savedFilters"]),
    ...mapGetters("todoModal", ["params"]),
    ...mapGetters("todoRoute", ["routeBoardId"])
  },
  methods: {
    ...mapActions("todoFilter", ["saveFilter", "updateFilter", "deleteFilter"]),
    ...mapActions("confirm", ["confirm", "disagree", "agree"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    deleteConfirm() {
      this.confirm({
        message: `${this.filterName} 필터를 삭제 하시겠습니까?`,
        callback: () => {
          this.deleteFilter({
            boardId: this.routeBoardId,
            id: this.params.filterId
          });
        },
        showCloseBtn: true
      });
    },
    save() {
      if (this.filtersIds.isFilter && !isBlank(this.filterName)) {
        this[this.params.type === "add" ? "saveFilter" : "updateFilter"]({
          boardId: this.routeBoardId,
          id: this?.params?.filterId || 0,
          filterName: this.filterName,
          isDefault: this.isDefault ? 1 : 0
        });
        return;
      }

      this.openSnackbar({
        message: "필터를 완성해주세요.",
        type: "VALIDATION"
      });
    }
  }
};
</script>
