import moment from "moment";
import headerUtils from "@/todo/store/utils/header";
const key = "prj_1";

const now = moment();
now.add(-7, "days");

export default {
  /**
   * headerUtils.TITLE, headerUtils.ADDITIONAL 두개는 필수 값 (정해진 순서대로)
   */
  genHeader: () => [
    { ...headerUtils.TITLE(), value: key + "_title" },
    {
      ...headerUtils.PERSON(),
      value: key + "_person",
      title: "todo.PRJ#1_담당자",
      width: 130
    },
    {
      ...headerUtils.TIMELINE(),
      value: key + "_timeline_1",
      title: "todo.PRJ#1_타임라인",
      connectedColumn: `${key}_number_1,${key}_number_2`
    },
    {
      ...headerUtils.NUMBER(),
      value: key + "_number_1",
      title: "todo.PRJ#1_기간",
      format: "default", // 기간 (기본값)
      combinedFormat: "column", // 열합, 열평균 (기본값)
      connectedColumn: key + "_timeline_1"
    },
    {
      ...headerUtils.NUMBER(),
      value: key + "_number_2",
      title: "todo.PRJ#1_진행 비율",
      width: 120,
      format: "percent", // 직척률
      combinedFormat: "row", // 타임라인 종합 기간, 타임라인 종합 진척률
      connectedColumn: key + "_timeline_1"
    },
    {
      ...headerUtils.STATUS(),
      value: key + "_status_1",
      title: "todo.PRJ#1_상태",
      width: 110,
      labels: [
        {
          title: "todo.PRJ#1_완료",
          color: "#4caf50",
          value: `${key}_status_1_0`
        },
        {
          title: "todo.PRJ#1_진행중",
          color: "#9c27b0",
          value: `${key}_status_1_1`
        },
        {
          title: "todo.PRJ#1_위험",
          color: "#f44336",
          value: `${key}_status_1_2`
        },
        {
          title: "",
          color: "#f2f2f2",
          defaultLabel: true,
          value: `${key}_status_1_3`
        }
      ]
    },
    { ...headerUtils.ADDITIONAL() }
  ],
  genGroups: () => [
    { title: "todo.PRJ#1_프로젝트 착수", color: "#9e9e9e" },
    { title: "todo.PRJ#1_프로젝트 계획", color: "#9e9e9e" },
    { title: "todo.PRJ#1_프로젝트 실행", color: "#9e9e9e" },
    { title: "todo.PRJ#1_프로젝트 종료", color: "#9e9e9e" }
  ],
  /**
   * genGroups의 size와 genItems 배열안에 배열개수를 맞춰줘야함.
   * ex) groups: [{}, {}] , items: [[{}, {}, {}], []]
   */
  genItems: () => [
    [
      {
        [key + "_title"]: "todo.PRJ#1_item-1",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(5, "days")
          .format("YYYY-MM-DD")}`,
        [key + "_status_1"]: `${key}_status_1_0`
      },
      {
        [key + "_title"]: "todo.PRJ#1_item-2",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(1, "days")
          .format("YYYY-MM-DD")}`
      }
    ],
    [
      {
        [key + "_title"]: "todo.PRJ#1_item-3",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(3, "days")
          .format("YYYY-MM-DD")}`
      },
      {
        [key + "_title"]: "todo.PRJ#1_item-4",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(1, "days")
          .format("YYYY-MM-DD")}`
      },
      {
        [key + "_title"]: "todo.PRJ#1_item-5",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(2, "days")
          .format("YYYY-MM-DD")}`
      }
    ],
    [
      {
        [key + "_title"]: "todo.PRJ#1_item-6"
      },
      {
        [key + "_title"]: "todo.PRJ#1_item-7"
      }
    ],
    [
      {
        [key + "_title"]: "todo.PRJ#1_item-8"
      },
      {
        [key + "_title"]: "todo.PRJ#1_item-9"
      }
    ]
  ]
};
