<template>
  <v-btn
    v-show="show"
    icon
    x-small
    class="cr-tooltip"
    @click="click"
    @mouseover="event => SET_TOOLTIP({ msg: '필터 수정', event })"
    @mouseout="HIDE_TOOLTIP"
  >
    <v-icon small>mdi-pencil-outline</v-icon>
  </v-btn>
</template>

<style lang="scss" scoped>
.v-btn {
  position: absolute;
  right: 2px;
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
    filter: {
      type: Object,
      default: () => ({})
    },
    hover: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("todoModal", ["params"]),
    show() {
      return this.hover && this.filter.id;
    }
  },
  methods: {
    ...mapMutations("todoModal", ["SET_MODAL", "CLOSE_MODAL"]),
    ...mapMutations("todoTooltip", ["SET_TOOLTIP", "HIDE_TOOLTIP"]),
    click(e) {
      const todoList = document.getElementById("todoList");
      const currentTab = e.target.closest(".v-tab");

      const modalWidth = 745;
      const { filterId } = this.params;
      const { right, width } = currentTab.getBoundingClientRect();
      const { left: l_left, right: l_right } = todoList.getBoundingClientRect();

      const maxLeft = l_right - l_left - modalWidth;
      let left = right - l_left - modalWidth / 2 - width / 2;
      if (left < 0) left = 0;
      if (left > maxLeft) left = maxLeft;
      left = `${left}px`;

      setTimeout(() => {
        const { id } = this.filter;
        if (filterId === id) return this.CLOSE_MODAL();

        const params = { type: "update", top: "66px", left, filterId: id };
        this.SET_MODAL({ type: "filter", params });
      }, 0);
    }
  }
};
</script>
