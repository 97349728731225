var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"columnref",attrs:{"dense":"","chips":"","clearable":"","deletable-chips":"","multiple":"","small-chips":"","outlined":"","hide-details":"","return-object":"","label":"숫자컬럼","item-text":"title","item-value":"value","disabled":_vm.loading,"loading":_vm.loading,"menu-props":{
          'offset-y': true,
          closeOnClick: true
        },"items":_vm.numberColumns},on:{"change":_vm.changeColumn},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
return [(item.type !== 'add')?_c('v-chip',{attrs:{"small":"","close":"","label":""},on:{"click:close":function($event){return _vm.deleteChip(item)}}},[_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"120px"}},[_c('span',[_vm._v(_vm._s(item.title))])])]):_vm._e()]}},{key:"item",fn:function(ref){
        var item = ref.item;
return [(item.type == 'add')?_c('div',[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" 새로운 컬럼 ")],1):_c('div',[_vm._v(" "+_vm._s(item.title)+" ")])]}}]),model:{value:(_vm.selectedColumn),callback:function ($$v) {_vm.selectedColumn=$$v},expression:"selectedColumn"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }