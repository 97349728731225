<template>
  <v-textarea
    ref="textRef"
    dense
    hide-details
    hide-spin-buttons
    no-resize
    outlined
    :wrap="isNotEmptyItem && !isDetail ? 'off' : ''"
    :rows="rows"
    :loading="loading"
    :disabled="loading"
    :auto-grow="isDetail"
    class="cr-edit-text"
    :class="{ 'cr-item-detail': isDetail }"
    :placeholder="isNotEmptyItem ? '' : '+ 아이템 추가'"
    v-model="data"
    @focus="inputFocus"
    @blur="inputBlur"
    @keydown.enter="enter"
    @keydown.esc="blurAction"
    @input="change"
    :value="inputValue"
  />
</template>

<style>
.cr-edit-text {
  -ms-overflow-style: none;
}

.cr-edit-text ::-webkit-scrollbar {
  display: none;
}
</style>
<style lang="scss" scoped>
.v-input.v-text-field::v-deep {
  .v-input__control {
    .v-input__slot {
      min-height: 36px;
      fieldset {
        border: none;
      }
      .v-text-field__slot {
        textarea {
          margin-top: 5px;
          min-height: 32px;
          max-height: 32px;
          margin-right: 10px;
        }
      }
    }
  }

  // 상세화면에서 스타일적용
  &.cr-item-detail {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    font-weight: bold;
    font-size: 1.4rem;
    padding: 15px 23px;
    .v-input__control {
      .v-input__slot {
        padding: 0px;
        min-height: 27px;

        .v-text-field__slot {
          margin-right: -4px;
          textarea {
            margin: 0px;
            padding-left: 0px;
            padding-right: 4px;
            min-height: 27px;
            max-height: 278px;
            overflow: auto;
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapMutations } from "vuex";
import { isBlank } from "@/commons/utils/validation";

export default {
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    header: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    isNotEmptyItem: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ""
    },
    inputValue: {
      type: String,
      default: ""
    },
    displayTextHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      pressEnter: false
    };
  },
  computed: {
    data: {
      get() {
        return this.text;
      },
      set(text) {
        this.$emit("update:text", text);
      }
    },
    rows() {
      if (!this.isDetail) return 1;
      return Math.floor((this.displayTextHeight - 31) / 28);
    }
  },
  methods: {
    ...mapMutations("loader", ["SET_SHOW"]),
    ...mapActions("todoItem", ["addItem", "updateItem"]),
    validTitle(title) {
      title = title
        .trim()
        .replace(/\r\n/gi, "")
        .replace(/\\n/gi, "")
        .replace(/\n/gi, "");
      return isBlank(title) || this.inputValue == title;
    },
    inputFocus() {
      if (this.isDetail) return;
      this.$el.parentNode.style.border = "1px solid var(--v-primary-base)";
      this.$el.style.paddingLeft = "0px";
      this.$el.style.paddingTop = "0px";
    },
    inputBlur(e) {
      const title = e.target.value;
      this.blurAction();

      if (this.validTitle(title)) return;
      this.add(title);
    },
    blurAction() {
      this.$emit("changeEditable", false);
      this.$el.parentNode.style.border = "";
      this.$el.style.paddingLeft = "";
      this.$el.style.paddingTop = "";
      this.$refs.textRef?.reset();
      this.data = this.inputValue;
      this.$refs.textRef.blur();
    },
    async enter(e) {
      const title = e.target.value;
      this.pressEnter = true;
      this.blurAction();

      if (this.validTitle(title)) return (this.pressEnter = false);
      await this.add(title);

      if (this.isNotEmptyItem) return;
      this.$nextTick(() => {
        this.$refs.textRef.focus();
      });
    },
    // 여러개 등록
    async change(txt) {
      if (!txt || this.isNotEmptyItem) return;
      if (this.pressEnter) {
        setTimeout(() => {
          this.data = "";
        }, 0);
        this.pressEnter = false;
      }

      const separator = "<;:;>";
      const regex = /\r\n/gi;
      const regex2 = /\\n/gi;
      const regex3 = /\n/gi;
      if (regex.test(txt) || regex2.test(txt) || regex3.test(txt)) {
        const txtArr = txt
          .replace(regex, separator)
          .replace(regex2, separator)
          .replace(regex3, separator)
          .split(separator);

        if (txtArr.length > 5) this.SET_SHOW(true);
        this.$refs.textRef.reset();
        for (let i = 0; i < txtArr.length; i += 1) {
          if (isBlank(txtArr[i])) continue;
          await this.add(txtArr[i]);
        }

        this.blurAction();
        this.SET_SHOW(false);
      }
    },
    async add(title) {
      this.loading = true;
      const { id: groupId, boardId } = this.group;
      const { id: itemId, parentId } = this.item ?? {};
      const { value: headerValue } = this.header;

      if (this.isNotEmptyItem) {
        await this.updateItem({
          boardId,
          groupId,
          itemId,
          parentId,
          headerValue,
          itemValue: title.trim(),
          prevValue: this.inputValue
        });
      } else {
        await this.addItem({ boardId, groupId, title: title.trim() });
      }

      this.loading = false;
      this.pressEnter = false;
    }
  }
};
</script>
