<template>
  <v-card v-show="show" class="cr-todo-detail-wrapper" @scroll="CLOSE_MENU">
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeDetail">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <div
      class="mx-auto d-flex justify-space-between"
      style="width: 1130px; padding-bottom: 68px;"
    >
      <div style="width: 800px;">
        <div style="background: #fff;">
          <Breadcrumbs
            :item="item"
            :group="group"
            :titleHeader="titleHeader"
            @closeDetail="closeDetail"
          />
          <Title
            ref="titleRef"
            :group="group"
            :item="item"
            :header="titleHeader"
            :isDetail="true"
          />
          <Content :item="item" />
          <CheckList ref="checkListRef" :item="item" />
          <Attach :item="item" />
          <Comment ref="commentRef" :item="item" :group="group" />
        </div>
      </div>

      <!-- 아이템 각컬럼 정보들 -->
      <Items ref="itemsRef" :item="item" :headers="headers" :group="group" />
    </div>
  </v-card>
</template>

<style>
.v-main__wrap {
  position: var(--v-main__wrap__position);
}
</style>
<style lang="scss" scoped>
.v-card.cr-todo-detail-wrapper {
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #eeeeee;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 6;
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import headerUtils from "@/todo/store/utils/header";
import Breadcrumbs from "./detail/Breadcrumbs.vue";
import Title from "./group/body/type/title";
import Content from "./detail/Content.vue";
import CheckList from "./detail/CheckList.vue";
import Attach from "./detail/Attach.vue";
import Comment from "./detail/Comment.vue";
import Items from "./detail/Items.vue";

export default {
  components: {
    Breadcrumbs,
    Title,
    Content,
    CheckList,
    Attach,
    Comment,
    Items
  },
  mounted() {
    document.addEventListener("keydown", this.esc, true);
    this.$root.$el.style.setProperty(
      "--v-main__wrap__position",
      `${this.show ? "unset" : "relative"}`
    );
  },
  destroyed() {
    document.removeEventListener("keydown", this.esc, true);
    this.$root.$el.style.setProperty("--v-main__wrap__position", "relative");
  },
  data: () => ({
    // 확인 버튼 클릭
    confirm: false,
    wrapperStyle: ""
  }),
  computed: {
    ...mapGetters("confirm", { showConfirm: "isShow" }),
    ...mapGetters("todoDialog", ["showDialog"]),
    ...mapGetters("todoMenu", { showMenu: "show" }),
    ...mapGetters("todoModal", { showModal: "show", modalType: "type" }),
    ...mapGetters("todoDetail", ["show"]),
    ...mapGetters("todoGroup", ["groups"]),
    ...mapGetters("todoItem", ["orgItems", "subItems"]),
    ...mapGetters("todoHeader", ["header", "titleHeader"]),
    ...mapGetters("todoRoute", [
      "routeWorkspaceId",
      "routeBoardId",
      "routeGroupId",
      "routeItemId",
      "routeParentId"
    ]),
    group() {
      const [group] = this.groups.filter(g => g.id === this.routeGroupId);
      return group ?? {};
    },
    item() {
      let items = this.orgItems[this.routeGroupId];
      if (this.routeParentId > 0) items = this.subItems[this.routeParentId];
      if (!Array.isArray(items)) return {};

      const [item] = items.filter(i => i.id === this.routeItemId);
      return item ?? {};
    },
    headers() {
      return this.header.filter(
        h =>
          h.type !== headerUtils.TITLE().type &&
          h.type !== headerUtils.ADDITIONAL().type
      );
    }
  },
  watch: {
    show(s) {
      this.$root.$el.style.setProperty(
        "--v-main__wrap__position",
        `${s ? "unset" : "relative"}`
      );
    }
  },
  methods: {
    ...mapMutations("todoMenu", ["CLOSE_MENU"]),
    ...mapActions("todoRoute", ["routeBoard", "routeBoardAction"]),
    esc(e) {
      // 컬럼 타이틀 / 텍스트필드 수정중 esc방지
      const { title = [] } = this.$refs.itemsRef.$refs;
      const [editable] = title.filter(vc => vc.editable);

      if (
        e.keyCode !== 27 ||
        !!editable ||
        this.$refs.titleRef.editable ||
        this.$refs.checkListRef.editable ||
        this.$refs.commentRef.editable ||
        this.showConfirm ||
        this.showDialog ||
        this.showMenu ||
        (this.showModal && this.modalType === "header")
      ) {
        return;
      }

      this.closeDetail();
    },
    closeDetail() {
      const { name, params } = this.$route;
      const { filterId, viewType } = JSON.parse(params?.actionObj || "{}");

      if (name !== "todo_board_action") return;

      this.routeBoardAction({
        workspaceId: this.routeWorkspaceId,
        boardId: this.routeBoardId,
        actionObj: JSON.stringify({ filterId, viewType })
      });
    }
  }
};
</script>
