<template>
  <v-hover v-slot="{ hover }">
    <div class="cr-check-item-wrapper">
      <div class="d-flex align-center py-1">
        <v-checkbox
          dense
          hide-details
          v-model="checkItem.checked"
          @change="itemCheck"
        />

        <v-text-field
          v-if="editable"
          ref="textRef"
          dense
          hide-details
          outlined
          :value="checkItem.title"
          @blur="esc"
          @keydown.esc="esc"
          @keydown.enter="enter"
        />

        <div v-else class="cr-checkitem" @click="changeEditable">
          {{ checkItem.title }}
        </div>
        <v-btn v-if="!editable && hover" icon @click="deleteItem">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </div>
      <!-- <div class="d-flex justify-end px-3 grey--text text--light-2 text-body-2">
        마지막 수정자:
        <span class="ml-3">{{ lastAuthorName }}</span>
        <span class="ml-1 mr-3">({{ lastAuthorEmail }})</span>
        <span class="text-caption">{{ updateTime }}</span>
      </div> -->
    </div>
  </v-hover>
</template>

<style lang="scss" scoped>
.cr-check-item-wrapper {
  padding: 0px 10px;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  .cr-checkitem {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 11px;
    border: thin solid transparent;
    &:hover {
      border: thin solid rgba(0, 0, 0, 0.12);
      cursor: text;
    }
  }
}
</style>

<script>
import { mapActions } from "vuex";
import { isBlank } from "@/commons/utils/validation";
import { getFullDate } from "@/commons/utils/moment";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    checkItem: {
      type: Object,
      default: () => ({})
    },
    idx: {
      type: Number,
      default: 0
    },
    parentEditable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editable: false
    };
  },
  computed: {
    lastAuthorName() {
      if (this.checkItem.lastAuthor) {
        return this.checkItem.lastAuthor.split("||")[0];
      }
      return "";
    },
    lastAuthorEmail() {
      if (this.checkItem.lastAuthor) {
        return this.checkItem.lastAuthor.split("||")[1];
      }
      return "";
    },
    updateTime() {
      if (this.checkItem.updatedTimeMillis) {
        return getFullDate(this.checkItem.updatedTimeMillis);
      }
      return "";
    }
  },
  watch: {
    editable(n) {
      this.$emit("update:parentEditable", n);
    }
  },
  methods: {
    ...mapActions("todoDetail", [
      "updateDetailCheckListTitle",
      "updateDetailCheckListChecked",
      "deleteDetailCheckList"
    ]),
    changeEditable() {
      this.editable = true;
      this.$nextTick(() => {
        this.$refs.textRef.focus();
      });
    },
    esc() {
      this.editable = false;
      this.$refs.textRef.reset();
    },
    enter(e) {
      const title = e.target.value.trim();
      if (isBlank(title)) return;

      const {
        item: { id: itemId, boardId },
        checkItem: { id }
      } = this;
      this.updateDetailCheckListTitle({ id, itemId, boardId, title });
      this.esc();
    },
    itemCheck(checked) {
      const {
        item: { id: itemId, boardId },
        checkItem: { id }
      } = this;
      this.updateDetailCheckListChecked({ id, itemId, boardId, checked });
    },
    deleteItem() {
      const {
        item: { id: itemId, boardId },
        checkItem: { id }
      } = this;
      this.deleteDetailCheckList({ id, itemId, boardId });
    }
  }
};
</script>
