import headerUtils from "@/todo/store/utils/header";
const key = "pri_2";

export default {
  genHeader: () => [
    { ...headerUtils.TITLE(), value: `${key}_title` },
    { ...headerUtils.CREATED_LOG(), title: "todo.PRI#2_등록일", width: 220 },
    { ...headerUtils.ADDITIONAL() }
  ],
  genGroups: () => [{ title: "todo.PRI#2_todo", color: "#3F51B5" }],
  genItems: () => [
    [
      {
        [`${key}_title`]: "todo.PRI#2_item-1"
      },
      {
        [`${key}_title`]: "todo.PRI#2_item-2"
      },
      {
        [`${key}_title`]: "todo.PRI#2_item-3"
      }
    ]
  ]
};
