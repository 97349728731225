import moment from "moment";
import headerUtils from "@/todo/store/utils/header";
const key = "crm_1";

const now = moment();
now.add(-14, "days");

export default {
  /**
   * headerUtils.TITLE, headerUtils.ADDITIONAL 두개는 필수 값 (정해진 순서대로)
   */
  genHeader: () => [
    { ...headerUtils.TITLE(), value: key + "_title" },
    {
      ...headerUtils.TEXT(),
      value: key + "_domain_1",
      title: "todo.CRM#1_도메인",
      width: 160
    },
    {
      ...headerUtils.PERSON(),
      value: key + "_person_1",
      title: "todo.CRM#1_영업 담당자",
      width: 145
    },
    {
      ...headerUtils.PERSON(),
      value: key + "_person_2",
      title: "todo.CRM#1_고객 담당자",
      width: 145
    },
    {
      ...headerUtils.TIMELINE(),
      value: key + "_timeline_1",
      title: "todo.CRM#1_서비스 개시일"
    },
    {
      ...headerUtils.STATUS(),
      value: key + "_status_1",
      title: "todo.CRM#1_중요도",
      width: 110,
      labels: [
        {
          title: "todo.CRM#1_Low",
          color: "#A5D6A7",
          value: `${key}_status_1_0`
        },
        {
          title: "todo.CRM#1_Medium",
          color: "#2196F3",
          value: `${key}_status_1_1`
        },
        {
          title: "todo.CRM#1_High",
          color: "#FF9800",
          value: `${key}_status_1_2`
        },
        {
          title: "",
          color: "#f2f2f2",
          defaultLabel: true,
          value: `${key}_status_1_3`
        }
      ]
    },
    {
      ...headerUtils.TEXT(),
      value: key + "_desc_1",
      title: "todo.CRM#1_설명",
      width: 220
    },
    { ...headerUtils.ADDITIONAL() }
  ],
  genGroups: () => [
    { title: "todo.CRM#1_활성 고객사", color: "#3F51B5" },
    { title: "todo.CRM#1_해지 고객사", color: "#9E9E9E" }
  ],
  /**
   * genGroups의 size와 genItems 배열안에 배열개수를 맞춰줘야함.
   * ex) groups: [{}, {}] , items: [[{}, {}, {}], []]
   */
  genItems: () => [
    [
      {
        [key + "_title"]: "todo.CRM#1_item-1",
        [key + "_domain_1"]: "samsung.com",
        [key + "_status_1"]: `${key}_status_1_2`,
        [key + "_desc_1"]: "company description",
        [key + "_timeline_1"]: "1969-01-13,1969-01-13"
      },
      {
        [key + "_title"]: "todo.CRM#1_item-2",
        [key + "_domain_1"]: "apple.com",
        [key + "_status_1"]: `${key}_status_1_1`,
        [key + "_timeline_1"]: "1976-04-01,1976-04-01"
      },
      {
        [key + "_title"]: "todo.CRM#1_item-3",
        [key + "_domain_1"]: "lg.co.kr",
        [key + "_status_1"]: `${key}_status_1_2`,
        [key + "_timeline_1"]: "1996-03-27,1996-03-27"
      },
      {
        [key + "_title"]: "todo.CRM#1_item-4",
        [key + "_domain_1"]: "mi.com",
        [key + "_status_1"]: `${key}_status_1_0`,
        [key + "_timeline_1"]: "2000-01-01,2000-01-01"
      }
    ],
    [
      {
        [key + "_title"]: "todo.CRM#1_item-5",
        [key + "_domain_1"]: "3rsoft.com",
        [key + "_status_1"]: `${key}_status_1_0`,
        [key + "_timeline_1"]: "1998-11-01,1998-11-01"
      }
    ]
  ]
};
