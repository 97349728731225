<template>
  <v-container :fluid="isBoardType">
    <!-- 폴더추가 -->
    <v-row v-if="!isBoardType">
      <v-col cols="12">
        <v-text-field
          required
          autofocus
          autocomplete="off"
          ref="titleRef"
          :label="`${params.type == 'FOLDER' ? '폴더' : '보드'} 이름*`"
          v-model="title"
          v-on:keyup.enter="() => $emit('update:confirm', true)"
        />
      </v-col>
    </v-row>
    <!-- 보드추가 (템플릿) -->
    <div v-else>
      <div
        v-for="(category, idx) in categories"
        :key="idx"
        class="d-flex flex-wrap mt-2"
        :style="showProgress ? 'opacity: 0.2' : ''"
      >
        <div class="title font-weight-black pa-2" style="width: 100%;">
          {{ $t(category) }}
        </div>
        <div
          v-for="tmpl in templates(category)"
          :key="tmpl.key"
          class="d-flex py-2 px-4"
        >
          <Tempalte :tmpl="tmpl" @showProgress="showProgressFunc" />
        </div>
      </div>
      <div v-if="showProgress" class="cr-progress-circular">
        <h3 class="mb-8">{{ progressMSG }}</h3>
        <v-progress-linear v-model="progress" height="36" color="orange">
          <strong>{{ progress }}%</strong>
        </v-progress-linear>
      </div>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
.cr-progress-circular {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.06);
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;

  .v-progress-linear {
    width: 30%;
    border-radius: 4px;
  }
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { isBlank } from "@/commons/utils/validation";
import templates from "@/todo/store/utils/template";
import Tempalte from "./Template.vue";

export default {
  components: {
    Tempalte
  },
  props: {
    confirm: {
      default: false,
      type: Boolean
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  data() {
    return {
      title: "",
      showProgress: false,
      progress: 0,
      progressMSG: "가져오는중...",
      msgChangedTime: Math.random() * (1500 - 700) + 700,
      interval: {}
    };
  },
  computed: {
    ...mapGetters("todoDialog", ["params", "showConfirmBtn"]),
    isBoardType() {
      return this.params?.type === "BOARD";
    },
    categories() {
      return Object.keys(templates);
    },
    templates: () => category => {
      return templates[category];
    }
  },
  watch: {
    confirm(next) {
      if (next && this.showConfirmBtn) {
        this.addBoardFunc();
      }
    },
    progress(n) {
      if (n * 20 > this.msgChangedTime) {
        this.progressMSG = "템플릿을 생성하는 중입니다.";
      }
      if (n < 100) return;

      setTimeout(() => {
        this.progress = 0;
        this.CLOSE_DIALOG();
      }, 300);
    }
  },
  methods: {
    ...mapMutations("todoDialog", ["CLOSE_DIALOG", "SET_PREVENT_CLOSE"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("todoBoard", ["addBoard"]),
    // 중복 방지 위해 엔터와 확인 버튼 둘다 confirm변경
    async addBoardFunc() {
      const { title, params } = this;
      const { type, workspaceId, parentId = 0 } = params ?? {};
      this.title = title.trim();

      if (isBlank(this.title)) {
        this.openSnackbar({
          message: `${type == "FOLDER" ? "폴더" : "보드"} 이름은 필수값입니다.`,
          type: "VALIDATION"
        });
        this.$emit("update:confirm", false);
        return this.$refs.titleRef.focus();
      }

      this.addBoard({ workspaceId, title, boardType: type, parentId });
      this.CLOSE_DIALOG();
    },
    showProgressFunc() {
      this.SET_PREVENT_CLOSE(true);
      this.$emit("update:confirm", true);
      this.showProgress = true;
      this.interval = setInterval(() => {
        if (this.progress === 100) {
          return;
        }
        this.progress += 10;
      }, 200);
    }
  }
};
</script>
