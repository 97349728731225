<template>
  <div
    :title="inputValue"
    class="cr-item-title-root"
    :class="{ 'cr-detail': isDetail }"
  >
    <Prepend
      v-if="isNotEmptyItem"
      v-bind="$props"
      v-on="$listeners"
      :editable="editable"
    />
    <DisplayText
      v-if="isNotEmptyItem && !editable"
      v-bind="$props"
      ref="displayText"
      :inputValue="inputValue"
      @changeEditable="changeEditable"
    />
    <EditText
      v-else
      v-bind="$props"
      ref="editText"
      :text.sync="text"
      :inputValue="inputValue"
      :isNotEmptyItem="isNotEmptyItem"
      :displayTextHeight="displayTextHeight"
      @changeEditable="changeEditable"
    />
    <v-progress-linear
      v-if="moveItemId == item.id"
      indeterminate
      :color="group.color"
      style="position: absolute; bottom: 0px; opacity: 0.6;"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-item-title-root::v-deep {
  height: 100%;
  max-height: 37px;
  display: flex;
  align-items: center;
  border: 1px solid;
  border-color: transparent;

  &.cr-detail {
    max-height: unset;
  }
  // 하위 아이템 확장 / 추가 버튼
  &:hover .cr-sub-btn {
    display: unset;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import Prepend from "./Prepend.vue";
import DisplayText from "./DisplayText.vue";
import EditText from "./EditText.vue";

export default {
  components: { Prepend, DisplayText, EditText },
  props: {
    group: {
      type: Object,
      default: () => ({})
    },
    header: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    },
    checked: {
      type: Boolean,
      default: false
    },
    isDetail: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.displayText) {
        this.displayTextHeight = this.$refs.displayText.$el.offsetHeight;
      }

      if (!this.isNotEmptyItem) return;
      this.text = this.item[this.header.value];
    });
  },
  data() {
    return {
      text: "",
      editable: false,
      displayTextHeight: 0
    };
  },
  computed: {
    ...mapGetters("todoItem", ["moveItemId"]),
    isNotEmptyItem() {
      return (
        this.item?.constructor === Object && Object.keys(this.item).length > 0
      );
    },
    inputValue() {
      if (!this.isNotEmptyItem) return "";
      return this.item[this.header.value];
    }
  },
  watch: {
    inputValue(value) {
      this.text = value;
    }
  },
  methods: {
    changeEditable(editable) {
      this.editable = editable;
      this.$nextTick(() => {
        if (!this.$refs.editText || !editable) return;
        this.$refs.editText.$refs?.textRef?.focus();
      });
    }
  }
};
</script>
