<template>
  <div class="mr-2" style="width: 200px;">
    <v-select
      dense
      outlined
      hide-details
      return-object
      ref="conditionref"
      label="조건"
      :disabled="!column.value"
      :items="conditions(column.type)"
      item-text="title"
      item-value="title"
      :menu-props="{
        offsetY: true,
        closeOnClick: true,
        contentClass: 'cr-modal-close-prevent'
      }"
      v-model="filter.condition"
      @change="conditionChange"
      @click:append.stop="$refs.conditionref.activateMenu()"
    />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import headerUtils from "@/todo/store/utils/header";

export default {
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    filter: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    column() {
      return this.filter?.column ?? {};
    },
    conditions() {
      return type => {
        switch (type) {
          case headerUtils.TITLE().type:
            return [
              { title: "일치", value: "is", el: "select" },
              { title: "일치하지 않는", value: "is not", el: "select" },
              { title: "포함", value: "includes", el: "input" },
              { title: "포함하지 않는", value: "not includes", el: "input" }
            ];
          case headerUtils.PERSON().type:
            return [
              { title: "일치", value: "includes", el: "select" },
              { title: "일치하지 않는", value: "not includes", el: "select" },
              { title: "포함", value: "includes", el: "input" },
              { title: "포함하지 않는", value: "not includes", el: "input" },
              { title: "비어있는", value: "empty" },
              { title: "비어있지 않는", value: "not empty" }
            ];
          case headerUtils.STATUS().type:
            return [
              { title: "일치", value: "is", el: "select" },
              { title: "일치하지 않는", value: "is not", el: "select" },
              { title: "비어있는", value: "empty" },
              { title: "비어있지 않는", value: "not empty" }
            ];
          case headerUtils.TIMELINE().type:
            return [
              { title: "일치", value: "is", el: "select" },
              { title: "일치하지 않는", value: "is not", el: "select" },
              { title: "비어있는", value: "empty" },
              { title: "비어있지 않는", value: "not empty" },
              { title: "시작하는 날짜로", value: "starts between", el: "cal" },
              { title: "끝나는 날짜로", value: "ends between", el: "cal" }
            ];
          case headerUtils.CREATED_LOG().type:
            return [
              { title: "일치", value: "is", el: "select" },
              { title: "일치하지 않는", value: "is not", el: "select" }
            ];
          case headerUtils.TEXT().type:
            return [
              { title: "일치", value: "is", el: "select" },
              { title: "일치하지 않는", value: "is not", el: "select" },
              { title: "포함", value: "includes", el: "input" },
              { title: "포함하지 않는", value: "not includes", el: "input" },
              { title: "비어있는", value: "empty" },
              { title: "비어있지 않는", value: "not empty" }
            ];
          case headerUtils.LINK().type:
            return [
              { title: "일치", value: "is", el: "select" },
              { title: "일치하지 않는", value: "is not", el: "select" },
              { title: "포함", value: "includes", el: "input" },
              { title: "포함하지 않는", value: "not includes", el: "input" },
              { title: "비어있는", value: "empty" },
              { title: "비어있지 않는", value: "not empty" }
            ];
          case headerUtils.CHECK().type:
            return [
              { title: "체크되어 있는", value: "not empty" },
              { title: "체크되어 있지 않은", value: "empty" }
            ];
          default:
            return [];
        }
      };
    }
  },
  methods: {
    ...mapMutations("todoFilter", ["SET_FILTERS"]),
    conditionChange() {
      const { value } = this.filter.condition ?? {};
      const isEmpty = value == "empty" || value == "not empty";
      this.filter.values = isEmpty ? [{ title: "", value: "" }] : [];
      this.SET_FILTERS([...this.filters.map(f => ({ ...f }))]);
    }
  }
};
</script>
