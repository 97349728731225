<!--
  v-dialog / persistent : 밖에화면 눌렀을때 안꺼짐
  부모 컴포넌트에서 dialog 컨트롤
  :value="dialog"
  @input="$emit('update:dialog', false)"
-->
<template>
  <v-dialog
    scrollable
    :fullscreen="fullscreen"
    :persistent="fullscreen && confirm"
    :width="maxWidth"
    :max-width="maxWidth"
    :value="showDialog"
    @input="CLOSE_DIALOG"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6 font-weight-bold">{{ headline }}</span>
        <v-spacer></v-spacer>
        <v-btn v-if="fullscreen" icon @click="CLOSE_DIALOG">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <DialogContent :confirm.sync="confirm" :style="contentStyle" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text outlined @click="CLOSE_DIALOG">
          {{ btnTitles.cancel }}
        </v-btn>
        <v-btn
          v-show="showConfirmBtn"
          outlined
          color="accent"
          @click="confirm = true"
        >
          {{ btnTitles.confirm }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.v-dialog__content >>> .v-dialog {
  overflow: hidden;
}
.v-dialog__content >>> .v-dialog > .v-card > .v-card__text {
  overflow: auto;
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import DialogContent from "./dialog/index.vue";

export default {
  components: { DialogContent },
  data: () => ({
    // 확인 버튼 클릭
    confirm: false
  }),
  computed: {
    ...mapGetters("todoDialog", [
      "headline",
      "showDialog",
      "dialogType",
      "params",
      "fullscreen",
      "btnTitles",
      "showConfirmBtn"
    ]),
    maxWidth() {
      switch (this.dialogType) {
        default:
          return 400;
      }
    },
    contentStyle() {
      switch (this.dialogType) {
        default:
          return "";
      }
    }
  },
  watch: {
    dialogType() {
      this.confirm = false;
    }
  },
  methods: {
    ...mapMutations("todoDialog", ["CLOSE_DIALOG"])
  }
};
</script>
