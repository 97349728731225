<template>
  <div class="cr-bread-wrapper d-flex align-center justify-space-between">
    <v-breadcrumbs large :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :href="item.href"
          :disabled="item.disabled"
          :title="item.title"
        >
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>

    <v-btn outlined color="red" class="mr-2" @click="deleteItemsFunc">
      삭제
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.cr-bread-wrapper {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    group: {
      type: Object,
      default: () => ({})
    },
    titleHeader: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("todoBoard", ["currentBoard"]),
    ...mapGetters("todoRoute", ["routeBoardId"]),
    breadcrumbs() {
      const { title: boardTitle, id: boardId, workspaceId } = this.currentBoard;
      const { title: groupTitle } = this.group;
      const { title: itemTitle } = this.titleHeader;
      return [
        {
          text: boardTitle,
          disabled: false,
          href: `#/todo/${workspaceId}/${boardId}`,
          title: "보드"
        },
        {
          text: groupTitle,
          disabled: true,
          title: "그룹"
        },
        {
          text: itemTitle,
          disabled: true,
          title: "아이템"
        }
      ];
    }
  },
  methods: {
    ...mapActions("todoItem", ["deleteItems"]),
    ...mapActions("confirm", ["confirm", "disagree", "agree"]),
    deleteItemsFunc() {
      this.confirm({
        message: `아이템을 삭제하시겠습니까?<br/>삭제 후 복구할 수 없습니다.`,
        callback: async () => {
          await this.deleteItems({
            boardId: this.routeBoardId,
            itemIds: [this.item.id],
            items: [this.item]
          });

          this.$emit("closeDetail");
        },
        showCloseBtn: true
      });
    }
  }
};
</script>
