<template>
  <div class="cr-todo-main">
    <!-- 모든 워크스페이스 -->
    <WorkspaceList v-if="isRender('todo')" />
    <!-- 워크스페이스 메인화면 -->
    <WorkspaceMain v-if="isRender('workspace')" />
    <!-- 툴바 -->
    <Toolbar v-if="isRender('toolbar')" />
    <!-- 보드 입장시 그룹 목록 테이블 / 칸반보드 / 간트차트-->
    <GroupList v-if="isRender('board_table')" :key="`list_${key}`" />
    <Kanban v-if="isRender('board_kanban')" :key="`kanban_${key}`" />
    <Gantt v-if="isRender('board_gantt')" :key="`gantt_${key}`" />
    <!-- 아이템 선택 모달 / 필터 모달 / 헤더 메뉴 모달 -->
    <Modal />
    <!-- 아이템 상세 화면 -->
    <Detail :key="`detail_${detailKey}`" />
    <!-- 다이얼로그 -->
    <Dialog />
    <!-- 툴팁 -->
    <Tooltip />

    <WorkflowTutorial />
  </div>
</template>

<style lang="scss" scoped>
.cr-todo-main::v-deep {
  height: 100%;
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import Toolbar from "./components/main/toolbar";
import Detail from "./components/main/Detail.vue";
import Modal from "./components/main/Modal.vue";
import Dialog from "./components/main/Dialog.vue";
import Tooltip from "./components/main/Tooltip.vue";
import WorkflowTutorial from "@/commons/views/tutorial/WorkflowTutorial";

export default {
  components: {
    WorkspaceList: () => import("./components/main/WorkspaceList.vue"),
    WorkspaceMain: () => import("./components/main/WorkspaceMain.vue"),
    Toolbar,
    GroupList: () => import("./components/main/GroupList.vue"),
    Kanban: () => import("./components/main/Kanban.vue"),
    Gantt: () => import("./components/main/Gantt.vue"),
    Detail,
    Modal,
    Dialog,
    Tooltip,
    WorkflowTutorial
  },
  mounted() {
    HTMLCollection.prototype.indexOf = Array.prototype.indexOf;
    HTMLCollection.prototype.forEach = Array.prototype.forEach;
    HTMLCollection.prototype.at = Array.prototype.at;
  },
  computed: {
    ...mapGetters("auth", ["getPlanType"]),
    ...mapGetters("todoDialog", ["preventClose"]),
    ...mapGetters("todoRoute", {
      key: "routeBoardId",
      viewType: "viewType",
      viewTypes: "viewTypes",
      detailKey: "routeItemId"
    }),
    isRender: vue => v => {
      const { $route, viewType, viewTypes, getPlanType } = vue;
      const { name: routeName } = $route;

      // planType이 ADVANCED가 아니라면 간트차트 화면 렌더링 X
      if (viewType === "gantt" && getPlanType !== "ADVANCED") return false;
      // toolbar는 viewTypes만 사용
      if (v === "toolbar") return viewTypes.map(v => v.type).includes(viewType);
      if (v.includes("board")) return v.split("_")[1] === viewType;
      return (routeName.split("_")[1] || "todo") == v;
    }
  },
  methods: {
    ...mapMutations("splash", ["SET_SHOW_SPLASH"]),
    ...mapMutations("todoDialog", ["CLOSE_DIALOG"]),
    ...mapMutations("todoModal", ["CLOSE_MODAL"]),
    ...mapMutations("todoItem", ["RESET_SELECTED_ITEMS"]),
    beforeRoute(to, from) {
      const { name: toName, params: toParams } = to;
      const { name: fromName, params: fromParams } = from;

      this.SET_SHOW_SPLASH(false);
      if (!this.preventClose) this.CLOSE_DIALOG();
      if (fromName === "todo_board_action" || toName === "todo_board_action") {
        const { itemId: itemId } = JSON.parse(fromParams?.actionObj || "{}");
        const { itemId: toItemId } = JSON.parse(toParams?.actionObj || "{}");

        // itemId 존재시 상세화면
        if (itemId || toItemId) return;
      }

      this.CLOSE_MODAL();
      this.RESET_SELECTED_ITEMS();
    }
  },

  /**
   * 처음 들어올때 setParams -> Enter,
   * 라우트 이동시 Leave -> setParams -> Enter
   * 같은 라우트 이동시 setParams -> Update
   *
   * 같은 라우트 이동시 파라미터까지 동일하면 라우트 중복에러 발생
   * 위와 같은 경우 초기화는 route.js 에서 처리
   *
   */
  beforeRouteEnter(to, from, next) {
    next(vm => vm.beforeRoute(to, from));
  },
  beforeRouteUpdate(to, from, next) {
    this.beforeRoute(to, from);
    next();
  }
};
</script>
