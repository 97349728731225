<template>
  <v-menu
    offset-y
    :nudge-left="nudgeLeft"
    :nudge-top="nudgeTop"
    :close-on-content-click="false"
    v-model="openMenu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        v-bind="attrs"
        v-on="on"
        size="100"
        :class="cls"
        :color="workspace.emoji ? 'transparent' : color"
      >
        <span class="text-h4" :style="`color: ${invertColor(color)};`">
          {{ workspace.avatar }}
        </span>
      </v-avatar>
    </template>
    <div>
      <v-tabs height="40" show-arrows v-model="tab">
        <v-tab>색상</v-tab>
        <v-tab>이모지</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-color-picker
            width="330"
            swatches-max-height="200"
            hide-canvas
            hide-mode-switch
            hide-sliders
            hide-inputs
            show-swatches
            v-model="color"
          />
        </v-tab-item>
        <v-tab-item>
          <EmojiPicker @click:emoji="clickEmoji" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { invertColor } from "@/commons/utils/invertColor";
import EmojiPicker from "./EmojiPicker.vue";

export default {
  components: { EmojiPicker },
  props: {
    avatarCls: {
      type: String,
      default: ""
    },
    nudgeLeft: {
      type: [Number, String],
      default: 0
    },
    nudgeTop: {
      type: [Number, String],
      default: 0
    },
    workspace: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ["click:emoji", "click:color"],
  data() {
    return {
      tab: 0,
      openMenu: false
    };
  },
  computed: {
    ...mapGetters("todoRoute", ["routeWorkspaceId"]),
    color: {
      get() {
        return this.workspace.color ?? "#F44336";
      },
      set(color) {
        const { workspace, routeWorkspaceId: workspaceId } = this;
        if (color.toUpperCase() !== workspace.color.toUpperCase()) {
          if (this.$listeners["click:color"]) {
            this.$emit("click:color", color);
          } else {
            this.updateWorkspace({
              workspaceId,
              color,
              useEmoji: !!workspace.emoji
            });
          }

          this.openMenu = false;
        }
      }
    },
    cls() {
      let cls = "";
      if (this.workspace.emoji) {
        cls += " cr-workspace-avatar-emoji";
      }
      if (this.avatarCls) {
        cls += ` ${this.avatarCls}`;
      }

      return cls;
    }
  },
  methods: {
    ...mapActions("todoWorkspace", ["updateWorkspace"]),
    invertColor(color) {
      return invertColor(color);
    },
    clickEmoji(emoji) {
      if (this.$listeners["click:emoji"]) {
        this.$emit("click:emoji", emoji);
      } else {
        const { routeWorkspaceId: workspaceId } = this;
        this.updateWorkspace({ workspaceId, emoji, useEmoji: !!emoji });
      }

      this.openMenu = false;
    }
  }
};
</script>
