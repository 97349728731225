<template>
  <div :class="cls" :style="style">{{ msg }}</div>
</template>

<style lang="scss" scoped>
.cr-opacity-0 {
  opacity: 0 !important;
}
.cr-tooltip {
  position: absolute;
  opacity: 0.9;
  background: rgba(97, 97, 97, 0.9);
  color: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  padding: 5px 16px;
  text-transform: initial;
  width: auto;
  pointer-events: none;
  z-index: 8;
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  mounted() {
    document.addEventListener("mousemove", this.mousemove);
  },
  destroyed() {
    document.removeEventListener("mousemove", this.mousemove);
  },
  data() {
    return {
      tooltipWidth: 0,
      tooltipHeight: 0
    };
  },
  computed: {
    ...mapGetters("sidePanel", { sidePanelWidth: "renderedWidth" }),
    ...mapGetters("todoTooltip", [
      "show",
      "msg",
      "position",
      "width",
      "height",
      "top",
      "left",
      "pageX",
      "pageY"
    ]),
    cls() {
      let cls = "cr-tooltip";
      if (this.tooltipWidth === 0) cls += " cr-opacity-0";
      return cls;
    },
    style() {
      if (this.tooltipWidth === 0) return "";

      const { offsetWidth: rootWidth } = this.$root.$el;
      const { offsetWidth: mainWidth } = this.$parent?.$el ?? {};
      const appbarHeight = 64;
      const nudgeTop = 5;
      const nudgeLeft = 10;
      const drawerWidth = rootWidth - mainWidth - this.sidePanelWidth;

      let top = this.top + this.height + nudgeTop - appbarHeight;
      if (this.position === "top") {
        top = this.top - appbarHeight - this.tooltipHeight;
      }

      let left =
        this.left - drawerWidth + this.width / 2 - this.tooltipWidth / 2;

      if (this.position.startsWith("moving_")) {
        left = this.pageX - drawerWidth - this.tooltipWidth / 2;
        top = this.pageY - appbarHeight - this.tooltipHeight - 15;
      }

      // 오른쪽 공간 없을때
      if (mainWidth < left + this.tooltipWidth + nudgeLeft) {
        return `top: ${top}px; right: 10px;`;
      }

      return `top: ${top}px; left: ${left}px;`;
    }
  },
  watch: {
    msg(n) {
      this.$nextTick(() => {
        const { offsetWidth, offsetHeight } = this.$el;
        this.tooltipWidth = !n ? 0 : offsetWidth;
        this.tooltipHeight = !n ? 0 : offsetHeight;
      });
    }
  },
  methods: {
    ...mapMutations("todoTooltip", ["SET_MOVING_POSITION"]),
    mousemove(e) {
      if (!this.position.startsWith("moving_")) return;
      this.SET_MOVING_POSITION({ pageX: e.pageX, pageY: e.pageY });
    }
  }
};
</script>
