import moment from "moment";
import headerUtils from "@/todo/store/utils/header";
const key = "hr_1";

const now = moment();

export default {
  /**
   * headerUtils.TITLE, headerUtils.ADDITIONAL 두개는 필수 값 (정해진 순서대로)
   */
  genHeader: () => [
    { ...headerUtils.TITLE(), value: key + "_title" },
    {
      ...headerUtils.PERSON(),
      value: key + "_person",
      title: "todo.HR#1_채용 담당자",
      width: 130
    },
    {
      ...headerUtils.TIMELINE(),
      value: key + "_timeline_1",
      title: "todo.HR#1_접수일"
    },
    {
      ...headerUtils.STATUS(),
      value: key + "_status_1",
      title: "todo.HR#1_업무",
      labels: [
        {
          title: "todo.HR#1_영업",
          color: "#FFFFFF",
          value: `${key}_status_1_${0}`
        },
        {
          title: "todo.HR#1_마케팅",
          color: "#FFFFFF",
          value: `${key}_status_1_${1}`
        },
        {
          title: "todo.HR#1_개발",
          color: "#FFFFFF",
          value: `${key}_status_1_${2}`
        },
        {
          title: "",
          color: "#f2f2f2",
          defaultLabel: true,
          value: `${key}_status_1_${3}`
        }
      ]
    },
    {
      ...headerUtils.STATUS(),
      value: key + "_status_2",
      title: "todo.HR#1_부서",
      labels: [
        {
          title: "todo.HR#1_마케팅",
          color: "#DCEDC8",
          value: `${key}_status_2_${0}`
        },
        {
          title: "todo.HR#1_테크",
          color: "#FFF9C4",
          value: `${key}_status_2_${1}`
        },
        {
          title: "todo.HR#1_인사",
          color: "#FFE0B2",
          value: `${key}_status_2_${2}`
        },
        {
          title: "",
          color: "#f2f2f2",
          defaultLabel: true,
          value: `${key}_status_2_${3}`
        }
      ]
    },
    {
      ...headerUtils.STATUS(),
      value: key + "_status_3",
      width: 108,
      title: "todo.HR#1_서류 면접",
      labels: [
        {
          title: "todo.HR#1_합격",
          color: "#66BB6A",
          value: `${key}_status_3_${0}`
        },
        {
          title: "todo.HR#1_불합격",
          color: "#B71C1C",
          value: `${key}_status_3_${1}`
        },
        {
          title: "",
          color: "#f2f2f2",
          defaultLabel: true,
          value: `${key}_status_3_${2}`
        }
      ]
    },
    {
      ...headerUtils.STATUS(),
      value: key + "_status_4",
      width: 108,
      title: "todo.HR#1_1차 면접",
      labels: [
        {
          title: "todo.HR#1_합격",
          color: "#66BB6A",
          value: `${key}_status_4_${0}`
        },
        {
          title: "todo.HR#1_불합격",
          color: "#B71C1C",
          value: `${key}_status_4_${1}`
        },
        {
          title: "",
          color: "#f2f2f2",
          defaultLabel: true,
          value: `${key}_status_4_${2}`
        }
      ]
    },
    {
      ...headerUtils.STATUS(),
      value: key + "_status_5",
      width: 108,
      title: "todo.HR#1_2차 면접",
      labels: [
        {
          title: "todo.HR#1_합격",
          color: "#66BB6A",
          value: `${key}_status_5_${0}`
        },
        {
          title: "todo.HR#1_불합격",
          color: "#B71C1C",
          value: `${key}_status_5_${1}`
        },
        {
          title: "",
          color: "#f2f2f2",
          defaultLabel: true,
          value: `${key}_status_5_${2}`
        }
      ]
    },
    {
      ...headerUtils.TIMELINE(),
      value: key + "_timeline_2",
      title: "todo.HR#1_입사 예정일"
    },
    {
      ...headerUtils.TEXT(),
      value: key + "_text_1",
      title: "todo.HR#1_전화",
      width: 150
    },
    {
      ...headerUtils.TEXT(),
      value: key + "_text_2",
      title: "todo.HR#1_비고",
      width: 190
    },
    { ...headerUtils.ADDITIONAL() }
  ],
  genGroups: () => [
    { title: "todo.HR#1_지원자", color: "#4CAF50" },
    { title: "todo.HR#1_진행중", color: "#B71C1C" },
    { title: "todo.HR#1_고용됨", color: "#3F51B5" }
  ],
  /**
   * genGroups의 size와 genItems 배열안에 배열개수를 맞춰줘야함.
   * ex) groups: [{}, {}] , items: [[{}, {}, {}], []]
   */
  genItems: () => [
    [
      {
        [key + "_title"]: "todo.HR#1_item-4",

        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(-3, "days")
          .format("YYYY-MM-DD")}`,

        [key + "_status_1"]: `${key}_status_1_${1}`,
        [key + "_status_2"]: `${key}_status_2_${0}`,
        [key + "_text_1"]: "010 0000 0000"
      },
      {
        [key + "_title"]: "todo.HR#1_item-5",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(-1, "days")
          .format("YYYY-MM-DD")}`,
        [key + "_status_1"]: `${key}_status_1_${2}`,
        [key + "_status_2"]: `${key}_status_2_${1}`
      }
    ],
    [
      {
        [key + "_title"]: "todo.HR#1_item-2",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(-10, "days")
          .format("YYYY-MM-DD")}`,
        [key + "_status_1"]: `${key}_status_1_${2}`,
        [key + "_status_2"]: `${key}_status_2_${0}`,
        [key + "_status_3"]: `${key}_status_3_${0}`,
        [key + "_status_4"]: `${key}_status_4_${1}`,
        [key + "_status_5"]: `${key}_status_5_${1}`
      },
      {
        [key + "_title"]: "todo.HR#1_item-3",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(1, "days")
          .format("YYYY-MM-DD")}`,
        [key + "_status_1"]: `${key}_status_1_${1}`,
        [key + "_status_2"]: `${key}_status_2_${2}`,
        [key + "_status_3"]: `${key}_status_3_${0}`,
        [key + "_status_4"]: `${key}_status_4_${0}`,
        [key + "_status_5"]: `${key}_status_5_${1}`
      }
    ],
    [
      {
        [key + "_title"]: "todo.HR#1_item-1",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(-100, "days")
          .format("YYYY-MM-DD")}`,
        [key + "_status_1"]: `${key}_status_1_${0}`,
        [key + "_status_2"]: `${key}_status_2_${0}`,
        [key + "_status_3"]: `${key}_status_3_${0}`,
        [key + "_status_4"]: `${key}_status_4_${0}`,
        [key + "_status_5"]: `${key}_status_5_${0}`,
        [key + "_timeline_2"]: "2022-09-30,2022-09-30",
        [key + "_text_1"]: "010 0000 0000"
      }
    ]
  ]
};
