<template>
  <v-card
    outlined
    width="300"
    max-height="300"
    class="cr-pointer"
    @click="addBoardFunc"
  >
    <img
      height="150"
      width="300"
      class="pa-2"
      :src="
        tmpl.img
          ? require(`@/todo/assets/${tmpl.img}`)
          : require(`@/todo/assets/tpl_default.png`)
      "
    />
    <v-card-title>{{ $t(tmpl.title) }}</v-card-title>
    <v-card-text>
      <div class="cr_tpl_text" v-html="$t(tmpl.text)"></div>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.cr-pointer {
  cursor: pointer;

  &:hover {
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
      0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%) !important;
  }
}
.cr_tpl_text {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    tmpl: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("todoDialog", ["params"])
  },
  methods: {
    ...mapMutations("todoDialog", ["CLOSE_DIALOG"]),
    ...mapActions("todoBoard", ["addBoard"]),
    async addBoardFunc() {
      const { workspaceId, parentId = 0 } = this.params ?? {};
      const { key, title, genHeader, genGroups, genItems } = this.tmpl;

      this.addBoard({
        parentId,
        workspaceId,
        boardType: "BOARD",
        title: this.$t(title),
        header: genHeader().map(h => ({
          ...h,
          title: this.$t(h.title),
          labels: h.labels.map(l => ({ ...l, title: this.$t(l.title) }))
        })),
        groups: genGroups().map(g => ({ ...g, title: this.$t(g.title) })),
        items: genItems().map(
          arr =>
            arr?.map(i => ({
              ...i,
              ...(i[`${key}_title`]
                ? { [`${key}_title`]: this.$t(i[`${key}_title`]) }
                : {})
            })) ?? []
        )
      });
      this.$emit("showProgress");
    }
  }
};
</script>
