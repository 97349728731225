import moment from "moment";
import headerUtils from "@/todo/store/utils/header";
const key = "pri_1";

const now = moment();
now.add(-1, "days");

export default {
  /**
   * headerUtils.TITLE, headerUtils.ADDITIONAL 두개는 필수 값 (정해진 순서대로)
   */
  genHeader: () => [
    { ...headerUtils.TITLE(), value: key + "_title" },
    {
      ...headerUtils.STATUS(),
      value: key + "_status_2",
      title: "todo.PRI#1_상태",
      width: 110,
      labels: [
        {
          title: "todo.PRI#1_진행중",
          color: "#A5D6A7",
          value: `${key}_status_2_0`
        },
        {
          title: "todo.PRI#1_완료",
          color: "#2196F3",
          value: `${key}_status_2_1`
        },
        {
          title: "",
          color: "#f2f2f2",
          defaultLabel: true,
          value: `${key}_status_2_2`
        }
      ]
    },
    {
      ...headerUtils.TIMELINE(),
      value: key + "_timeline_1",
      title: "todo.PRI#1_마감일"
    },
    {
      ...headerUtils.STATUS(),
      value: key + "_status_1",
      title: "todo.PRI#1_중요도",
      width: 110,
      labels: [
        {
          title: "todo.PRI#1_Low",
          color: "#A5D6A7",
          value: `${key}_status_1_0`
        },
        {
          title: "todo.PRI#1_Medium",
          color: "#2196F3",
          value: `${key}_status_1_1`
        },
        {
          title: "todo.PRI#1_High",
          color: "#FF9800",
          value: `${key}_status_1_2`
        },
        {
          title: "",
          color: "#f2f2f2",
          defaultLabel: true,
          value: `${key}_status_1_3`
        }
      ]
    },
    { ...headerUtils.ADDITIONAL() }
  ],
  genGroups: () => [{ title: "todo.PRI#1_todo", color: "#3F51B5" }],
  /**
   * genGroups의 size와 genItems 배열안에 배열개수를 맞춰줘야함.
   * ex) groups: [{}, {}] , items: [[{}, {}, {}], []]
   */
  genItems: () => [
    [
      {
        [key + "_title"]: "todo.PRI#1_item-1",
        [key + "_status_2"]: `${key}_status_2_1`,
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now.format(
          "YYYY-MM-DD"
        )}`,
        [key + "_status_1"]: `${key}_status_1_2`
      },
      {
        [key + "_title"]: "todo.PRI#1_item-2"
      }
    ]
  ]
};
