<template>
  <div class="cr-todo-gantt-setting-content">
    <v-list-item class="pb-2 pt-1">
      <v-list-item-content class="px-2">
        <v-list-item-title class="mb-2">
          타임라인 컬럼
        </v-list-item-title>
        <div>
          <v-switch
            v-for="(timeline, idx) in timelineHeaders"
            :key="timeline.value"
            class="mt-1"
            dense
            hide-details
            inset
            :label="timeline.title"
            v-model="hideModels[idx]"
            @change="
              isHide =>
                SET_SHOW_COLUMNS({ value: timeline.value, isHide: !isHide })
            "
          />
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-divider />

    <v-list-item>
      <v-list-item-content class="px-2 pb-1">
        <v-switch
          class="mt-0"
          dense
          hide-details
          inset
          :label="'오늘 날짜 표시'"
          v-model="showTodayIndicationC"
        />
      </v-list-item-content>
    </v-list-item>

    <v-list-item class="pb-1">
      <v-list-item-content class="px-2">
        <v-switch
          class="mt-0"
          dense
          hide-details
          inset
          :label="'모든 아이템 표시'"
          v-model="showAllItemsC"
        />
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<style lang="scss" scoped>
.cr-todo-gantt-setting-content {
  width: 250px;
  max-height: 500px;
  overflow-y: auto;
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data({ $store }) {
    const timelineHeaders = $store.getters["todoHeader/timelineHeaders"];
    const hideColumns = $store.getters["todoGanttSetting/hideColumns"];

    return {
      hideModels: timelineHeaders.map(h => !hideColumns[h.value])
    };
  },
  computed: {
    ...mapGetters("todoHeader", ["timelineHeaders"]),
    ...mapGetters("todoGanttSetting", [
      "hideColumns",
      "showAllItems",
      "showTodayIndication"
    ]),
    showAllItemsC: {
      get() {
        return this.showAllItems;
      },
      set(show) {
        this.SET_SHOW_ALL_ITEMS(show);
      }
    },
    showTodayIndicationC: {
      get() {
        return this.showTodayIndication;
      },
      set(show) {
        this.SET_SHOW_TODAY_INDICATION(show);
      }
    }
  },
  methods: {
    ...mapMutations("todoGanttSetting", [
      "SET_SHOW_COLUMNS",
      "SET_SHOW_ALL_ITEMS",
      "SET_SHOW_TODAY_INDICATION"
    ])
  }
};
</script>
