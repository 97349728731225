import headerUtils from "@/todo/store/utils/header";
const key = "defaultTmpl";

export default {
  genHeader: () => [
    { ...headerUtils.TITLE() },
    { ...headerUtils.PERSON() },
    { ...headerUtils.TIMELINE() },
    {
      ...headerUtils.STATUS(),
      value: key + "_status",
      labels: [
        {
          title: "todo.완료",
          color: "#66BB6A",
          value: `${key}_status_${0}`
        },
        {
          title: "todo.진행중",
          color: "#FFEE58",
          value: `${key}_status_${1}`
        },
        {
          title: "todo.위험",
          color: "#B71C1C",
          value: `${key}_status_${2}`
        },
        {
          title: "",
          color: "#f2f2f2",
          defaultLabel: true,
          value: `${key}_status_${3}`
        }
      ]
    },
    { ...headerUtils.TEXT() },
    { ...headerUtils.CREATED_LOG() },
    { ...headerUtils.ADDITIONAL() }
  ],
  genGroups: () => [
    { title: "todo.새로운 그룹", color: "#4CAF50" },
    { title: "todo.새로운 그룹", color: "#4CAF50" }
  ],
  genItems: () => []
};
