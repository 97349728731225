<template>
  <v-hover v-slot="{ hover }">
    <div
      class="cr-timeline-wrapper cr-menu-close-prevent"
      :class="{
        'pr-5': hover,
        'cr-item-detail': isDetail,
        'cr-item-kanban': isKanban
      }"
      @click="openMenu"
    >
      <span
        class="cr-timeline text-truncate"
        :style="`background: ${linearGradient}`"
      >
        {{ itemValue(hover) }}
      </span>
      <v-btn v-if="hover" icon x-small class="cr-reset" @click.stop="reset">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </v-hover>
</template>

<style lang="scss" scoped>
.cr-timeline-wrapper {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0px 8px;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  .cr-timeline {
    font-size: 12px;
    border-radius: 14px;
    background: #e0e0e0;
    padding: 5px 13px;
    width: 100%;
    text-align: center;
  }

  .cr-reset {
    position: absolute;
    top: calc(50% - 10px);
    right: -1px;
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { getMonthAndDate } from "@/commons/utils/moment";

export default {
  props: {
    header: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    isKanban: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("todoRoute", ["viewType"]),
    ...mapGetters("sidePanel", { sidePanelWidth: "renderedWidth" }),
    dateRange() {
      const startRange = this.item[this.header.value]?.split(",") ?? [];
      if (startRange.length == 2) {
        const [first, second] = startRange;
        let sd = new Date(first).getTime();
        let ed = new Date(second).getTime();
        if (sd > ed) {
          sd = ed;
          ed = new Date(first).getTime();
        }

        return [sd, ed];
      }

      return null;
    },
    itemValue() {
      return hover => {
        if (Array.isArray(this.dateRange)) {
          const [start, end] = this.dateRange;
          if (hover) {
            return (end - start) / (1000 * 60 * 60 * 24) + 1 + "d";
          }

          if (start == end) {
            return getMonthAndDate(start);
          }

          return `${getMonthAndDate(start)} - ${getMonthAndDate(end)}`;
        }

        return "-";
      };
    },
    linearGradient() {
      if (Array.isArray(this.dateRange)) {
        const [start, end] = this.dateRange;
        const d1 = (end - start) / (1000 * 60 * 60 * 24) + 1;
        const d2 = (new Date().getTime() - start) / (1000 * 60 * 60 * 24) + 1;
        const percent = (Math.floor(d2) / d1) * 100;

        return `linear-gradient(to right, #a7c4ff ${percent}%, #e0e0e0 ${percent}%)`;
      }

      return "linear-gradient(to right, #a7c4ff 0%, #e0e0e0 0%)";
    }
  },
  methods: {
    ...mapActions("todoMenu", ["setMenu"]),
    ...mapActions("todoItem", ["updateItem"]),
    reset() {
      const { boardId, groupId, id: itemId, parentId } = this.item;
      const { value: headerValue } = this.header;

      this.updateItem({
        boardId,
        groupId,
        itemId,
        parentId,
        headerValue,
        itemValue: "",
        prevValue: this.item[headerValue]
      });
    },
    openMenu(e) {
      const target = e.target.closest(".cr-timeline-wrapper");
      if (!target) return;

      const { top, left, height, width } = target.getBoundingClientRect();
      const { offsetWidth: rootWidth } = this.$root.$el;
      const { offsetWidth: listWidth } = document.getElementById("todoList");
      const appbarHeight = 64;
      const toolbarHeight = this.viewType === "gantt" ? 116 : 76;
      const naviWidth = rootWidth - listWidth;
      const { id: itemId, parentId, boardId, groupId } = this.item;

      this.setMenu({
        type: "timeline",
        itemId,
        parentId,
        groupId,
        boardId,
        headerValue: this.header.value,
        top: top + height - appbarHeight - toolbarHeight,
        left: left - naviWidth + this.sidePanelWidth,
        targetWidth: width,
        targetHeight: height
      });
    }
  }
};
</script>
