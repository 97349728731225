<template>
  <div>
    <v-overlay :value="this.useWorkflow" opacity="0.6" z-index="9"></v-overlay>
    <div class="tutorial-container" v-if="this.useWorkflow">
      <div class="guide-container">
        <div>
          <v-btn icon x-large color="white" @click="prev" :disabled="hasPrev">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </div>
        <div style="position:relative;">
          <img
            :src="require(`@/commons/assets/${items[index].img}.png`)"
            @click="rotation"
          />
          <div class="guide-text" v-html="items[index].text"></div>
        </div>
        <div>
          <v-btn icon x-large color="white" @click="next" :disabled="hasNext">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>

      <!-- 하단 텍스트 상자 -->
      <div class="bottom-text-wrapper">
        <div class="text-1">
          {{ $t("tutorial.19") }}
        </div>
        <div class="text-2 mt-2">
          <a href="https://help.sirteam.net/user/main/wflow" target="_blank">
            {{ $t("tutorial.20") }}
          </a>
        </div>
        <img class="welcome" src="../../assets/help_man.png" />
      </div>

      <!-- 닫기 -->
      <v-btn x-large rounded class="close-button" @click="close">
        {{ $t("common.닫기") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import i18n from "@/_locales";

export default {
  data() {
    return {
      index: 0,
      items: [
        {
          img: "wf_tt_1",
          text: i18n.t("tutorial.39")
        },
        {
          img: "wf_tt_2",
          text: i18n.t("tutorial.40")
        },
        {
          img: "wf_tt_3",
          text: i18n.t("tutorial.41")
        }
      ]
    };
  },
  async mounted() {
    // 튜토리얼 초기화
    this.initTutorial({ module: "workflow" });
    if (this.useWorkflow) {
      this.index = 0;
    }
  },
  watch: {
    useWorkflow(value) {
      if (!value) return;
      this.index = 0;
    }
  },
  computed: {
    ...mapGetters("tutorial", ["useWorkflow", "getSpeed"]),
    ...mapGetters("auth", ["getUserInfo"]),
    hasNext() {
      return this.index >= 2;
    },
    hasPrev() {
      return this.index <= 0;
    }
  },
  methods: {
    ...mapActions("tutorial", ["initTutorial", "updateTutorialStatus"]),
    ...mapMutations("auth", ["SET_REQUEST_PASSWORD_CHANGE"]),
    prev() {
      this.index--;
    },
    next() {
      this.index++;
    },
    rotation() {
      if (this.index == 2) this.index = 0;
      else this.index++;
    },
    async close() {
      await this.updateTutorialStatus({ module: "workflow", value: false });

      const { requestPasswordChange = 0 } = this.getUserInfo;
      if (requestPasswordChange) {
        this.SET_REQUEST_PASSWORD_CHANGE(1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/* 튜토리얼 */
.tutorial-container {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;

  .guide-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .guide-text {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 164px;
    color: black;
    font-size: 17px;
    text-align: center;
    line-height: 1.7;
    vertical-align: top;
    padding: 52px 50px 10px 50px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: keep-all;
  }
}

/* 하단 텍스트 상자 */
.bottom-text-wrapper {
  position: fixed;
  bottom: 16px;
  right: 300px;
  color: white;
  padding: 20px 36px 22px;
  background-color: #6a8fff;
  border-radius: 30px;
  min-width: 400px;

  .text-1 {
    font-size: 18px;
    font-weight: bold;
  }

  .text-2 a {
    color: black;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    padding: 8px 12px;
    background-color: white;
    border-radius: 12px;
  }

  .welcome {
    position: absolute;
    top: -27px;
    right: 33px;
  }
}
/* 닫기 버튼 */
.close-button {
  position: absolute;
  right: 200px;
  bottom: 24px;
  margin-left: 34px;
  font-size: 20px;
  font-weight: bold;
  color: rgb(99, 138, 255);
  outline-width: 8px;
  outline-style: solid;
  outline-color: transparent;
}
</style>
