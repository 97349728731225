<template>
  <div class="cr-item-detail-comment-wrapper">
    <div class="text-subtitle-1 font-weight-bold py-2">
      <span>댓글</span>
    </div>

    <v-list>
      <CommentItem
        v-for="comment in commentList"
        :key="comment.id"
        :comment="comment"
        :item="item"
        :editable.sync="editable"
      />
    </v-list>

    <v-textarea
      ref="textRef"
      dense
      hide-details
      hide-spin-buttons
      no-resize
      outlined
      auto-grow
      rows="1"
      :loading="loading"
      :disabled="loading"
      v-model="text"
      placeholder="메시지를 입력해주세요"
      @focus="editable = true"
      @blur="editable = false"
      @keydown.esc="
        $refs.textRef.blur();
        $refs.textRef.reset();
      "
    >
    </v-textarea>
    <!-- justify-space-between -->
    <div class="d-flex py-2 justify-end">
      <!-- <v-btn depressed outlined color="primary">
        첨부
      </v-btn> -->
      <v-btn
        depressed
        color="primary"
        :disabled="loading"
        @click="addCommentFunc"
      >
        저장
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-item-detail-comment-wrapper::v-deep {
  padding: 16px 23px;
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { isBlank } from "@/commons/utils/validation";
import CommentItem from "./CommentItem.vue";

export default {
  components: { CommentItem },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    group: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      text: "",
      editable: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters("todoDetail", ["commentList"])
  },
  watch: {
    // 코멘트 입력창 엔터시 스크롤 아래로 이동
    text() {
      this.$nextTick(() => {
        // this.$parent.$el.scrollTop = this.$parent.$el.scrollHeight;
      });
    },
    commentList({ length }) {
      const {
        item: { id: itemId, groupId, parentId }
      } = this;
      this.SET_ITEM_ATTRS({
        itemId,
        groupId,
        parentId,
        keyValues: [{ key: "commentCnt", value: length }]
      });
    }
  },
  methods: {
    ...mapMutations("todoItem", ["SET_ITEM_ATTRS"]),
    ...mapActions("todoDetail", ["addComment"]),
    async addCommentFunc() {
      if (isBlank(this.text.trim())) return;

      const {
        item: { id: itemId, boardId }
      } = this;
      this.loading = true;
      const result = await this.addComment({
        itemId,
        boardId,
        content: this.text.trim()
      });

      if (result) {
        this.$refs.textRef.reset();
        this.$nextTick(() => {
          // this.$parent.$el.scrollTop = this.$parent.$el.scrollHeight;
        });
      }
      this.loading = false;
    }
  }
};
</script>
