<template>
  <div class="d-flex align-center cr-todo-selected-content">
    <div class="cr-todo-selected-cnt">
      {{ selectedCnt }}
    </div>
    <div class="pl-3">
      <span class="subtitle-1">선택된 아이템</span>
    </div>
    <v-spacer />
    <div class="pr-3">
      <v-btn icon @click="deleteItemsFunc">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-trash-can-outline</v-icon>
          </template>
          삭제
        </v-tooltip>
      </v-btn>
      <v-btn icon @click="close">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-close</v-icon>
          </template>
          닫기
        </v-tooltip>
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-todo-selected-content {
  height: 100%;
  width: 745px;

  .cr-todo-selected-cnt {
    background-color: var(--v-primary-base);
    width: 60px;
    height: 100%;
    font-size: 30px;
    color: #fff;
    text-align: center;
    line-height: 2;
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters("todoItem", ["selectedItems"]),
    ...mapGetters("todoRoute", ["routeBoardId"]),
    selectedCnt() {
      let cnt = 0;
      Object.keys(this.selectedItems).forEach(key => {
        cnt += this.selectedItems[key]?.length || 0;
      });

      return cnt;
    }
  },
  watch: {
    selectedCnt(selectedCnt) {
      if (selectedCnt > 0) return;
      this.CLOSE_MODAL();
    }
  },
  methods: {
    ...mapMutations("loader", ["SET_SHOW"]),
    ...mapMutations("todoModal", ["CLOSE_MODAL"]),
    ...mapMutations("todoItem", ["SET_SELECTED_ITEMS"]),
    ...mapActions("todoItem", ["deleteItems"]),
    ...mapActions("confirm", ["confirm", "disagree", "agree"]),
    close() {
      // 모달창 닫기
      this.CLOSE_MODAL();
      // 선택된 아이템 해제
      Object.keys(this.selectedItems).forEach(groupId => {
        this.SET_SELECTED_ITEMS({ groupId, selectedItems: [] });
      });
    },
    deleteItemsFunc() {
      this.confirm({
        message: `선택된 아이템을 삭제하시겠습니까?<br/>삭제 후 복구할 수 없습니다.`,
        callback: async () => {
          this.SET_SHOW(true);

          const items = [];
          const { routeBoardId: boardId, selectedItems } = this;
          Object.keys(selectedItems).forEach(groupId =>
            selectedItems[groupId]?.forEach(item => items.push(item))
          );

          const result = await this.deleteItems({ boardId, items });
          this.SET_SHOW(false);
          if (result) this.close();
        },
        showCloseBtn: true
      });
    }
  }
};
</script>
