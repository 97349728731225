<template>
  <div
    class="cr-person-wrapper cr-menu-close-prevent"
    :class="{ 'cr-item-detail': isDetail, 'cr-item-kanban': isKanban }"
    @click="openMenu"
  >
    <v-icon v-if="members.length == 0" color="#333">
      mdi-account-plus-outline
    </v-icon>
    <div v-else class="cr-person">
      <v-chip
        v-for="(member, idx) in members"
        :key="member.userId"
        small
        color="primary"
        class="px-2"
        :class="idx + 1 === members.length ? '' : 'mr-1'"
      >
        {{ member.name }}
      </v-chip>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-person-wrapper {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  .v-chip ::v-deep {
    cursor: pointer;
  }
  .cr-person {
    position: relative;
    padding: 0px 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0px;
    cursor: pointer;
    text-align: center;
  }

  &:hover::before {
    content: "";
    position: absolute;
    background-color: currentColor;
    opacity: 0.1;
    width: 100%;
    height: 100%;
    left: 0px;
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    header: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    isKanban: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("todoRoute", ["viewType"]),
    ...mapGetters("sidePanel", { sidePanelWidth: "renderedWidth" }),
    members() {
      try {
        return (
          this.item[this.header.value]
            ?.split(";")
            ?.map(member => JSON.parse(member)) ?? []
        );
      } catch (e) {
        return [];
      }
    }
  },
  methods: {
    ...mapActions("todoMenu", ["setMenu"]),
    openMenu(e) {
      const target = e.target.closest(".cr-person-wrapper");
      if (!target) return;

      const { top, left, height, width } = target.getBoundingClientRect();
      const { offsetWidth: rootWidth } = this.$root.$el;
      const { offsetWidth: listWidth } = document.getElementById("todoList");
      const appbarHeight = 64;
      const toolbarHeight = this.viewType === "gantt" ? 116 : 76;
      const naviWidth = rootWidth - listWidth;
      const { id: itemId, parentId, boardId, groupId } = this.item;

      this.setMenu({
        type: "person",
        itemId,
        parentId,
        groupId,
        boardId,
        headerValue: this.header.value,
        top: top + height - appbarHeight - toolbarHeight,
        left: left - naviWidth + this.sidePanelWidth,
        targetWidth: width,
        targetHeight: height
      });
    }
  }
};
</script>
