import moment from "moment";
import headerUtils from "@/todo/store/utils/header";
const key = "prj_3";

const now = moment();
now.add(-8, "days");

export default {
  /**
   * headerUtils.TITLE, headerUtils.ADDITIONAL 두개는 필수 값 (정해진 순서대로)
   */
  genHeader: () => [
    { ...headerUtils.TITLE(), value: key + "_title" },
    {
      ...headerUtils.PERSON(),
      value: key + "_person",
      title: "todo.PRJ#3_담당자",
      width: 130
    },
    {
      ...headerUtils.TIMELINE(),
      value: key + "_timeline_1",
      title: "todo.PRJ#3_타임라인",
      connectedColumn: `${key}_number_1,${key}_number_2`
    },
    {
      ...headerUtils.NUMBER(),
      value: key + "_number_1",
      title: "todo.PRJ#3_공수",
      format: "default", // 기간 (기본값)
      combinedFormat: "column", // 열합, 열평균 (기본값)
      connectedColumn: key + "_timeline_1"
    },
    {
      ...headerUtils.NUMBER(),
      value: key + "_number_2",
      title: "todo.PRJ#3_진척률",
      width: 120,
      format: "percent", // 직척률
      combinedFormat: "row", // 타임라인 종합 기간, 타임라인 종합 진척률
      connectedColumn: key + "_timeline_1"
    },
    { ...headerUtils.TEXT(), title: "todo.PRJ#3_비고", width: 200 },
    { ...headerUtils.ADDITIONAL() }
  ],
  genGroups: () => [
    { title: "todo.PRJ#3_분석 설계", color: "#3f51b5" },
    { title: "todo.PRJ#3_개발/구현", color: "#1b5e20" },
    { title: "todo.PRJ#3_테스트", color: "#e65100" },
    { title: "todo.PRJ#3_이행", color: "#4caf50" },
    { title: "todo.PRJ#3_프로젝트 종료", color: "#1a237e" }
  ],
  /**
   * genGroups의 size와 genItems 배열안에 배열개수를 맞춰줘야함.
   * ex) groups: [{}, {}] , items: [[{}, {}, {}], []]
   */
  genItems: () => [
    [
      {
        [key + "_title"]: "todo.PRJ#3_item-1",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(3, "days")
          .format("YYYY-MM-DD")}`
      },
      {
        [key + "_title"]: "todo.PRJ#3_item-2",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(3, "days")
          .format("YYYY-MM-DD")}`
      }
    ],
    [
      {
        [key + "_title"]: "todo.PRJ#3_item-3",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(3, "days")
          .format("YYYY-MM-DD")}`
      },
      {
        [key + "_title"]: "todo.PRJ#3_item-4",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(3, "days")
          .format("YYYY-MM-DD")}`
      }
    ],
    [
      {
        [key + "_title"]: "todo.PRJ#3_item-5",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(3, "days")
          .format("YYYY-MM-DD")}`
      },
      {
        [key + "_title"]: "todo.PRJ#3_item-6",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(3, "days")
          .format("YYYY-MM-DD")}`
      }
    ],
    [
      {
        [key + "_title"]: "todo.PRJ#3_item-7",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(3, "days")
          .format("YYYY-MM-DD")}`
      },
      {
        [key + "_title"]: "todo.PRJ#3_item-8",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(3, "days")
          .format("YYYY-MM-DD")}`
      },
      {
        [key + "_title"]: "todo.PRJ#3_item-9",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(3, "days")
          .format("YYYY-MM-DD")}`
      },
      {
        [key + "_title"]: "todo.PRJ#3_item-10",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(3, "days")
          .format("YYYY-MM-DD")}`
      }
    ],
    [
      {
        [key + "_title"]: "todo.PRJ#3_item-11",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(3, "days")
          .format("YYYY-MM-DD")}`
      },
      {
        [key + "_title"]: "todo.PRJ#3_item-12",
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(3, "days")
          .format("YYYY-MM-DD")}`
      }
    ]
  ]
};
