<template>
  <div class="cr-item-detail-check-list-wrapper">
    <div class="text-subtitle-1 font-weight-bold py-2">
      체크리스트
    </div>
    <div class="d-flex align-center">
      <v-progress-linear
        :value="progressValue"
        height="13"
        color="#66BB6A"
      ></v-progress-linear>
      <div class="ml-3 text-caption">
        {{ `${checkedCnt}/${totalCnt}` }}
      </div>
    </div>

    <CheckItem
      v-for="(checkItem, idx) in checkItems"
      :key="idx"
      :item="item"
      :checkItem="checkItem"
      :idx="idx"
      :parentEditable.sync="editable"
    />
    <v-text-field
      class="pt-2"
      ref="textRef"
      outlined
      dense
      hide-details
      placeholder="항목을 추가하세요"
      v-model="text"
      @focus="editable = true"
      @blur="editable = false"
      @keydown.esc="esc"
      @keydown.enter="enter"
    ></v-text-field>
  </div>
</template>

<style lang="scss" scoped>
.cr-item-detail-check-list-wrapper::v-deep {
  padding: 16px 23px;

  .v-input.v-input--checkbox {
    margin-top: 0px;
    padding-top: 0px;
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isBlank } from "@/commons/utils/validation";
import CheckItem from "./CheckItem.vue";

export default {
  components: { CheckItem },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      text: "",
      editable: false
    };
  },
  computed: {
    ...mapGetters("todoDetail", ["checkList"]),
    checkItems() {
      return this.checkList.map(c => ({ ...c }));
    },
    checkedCnt() {
      return this.checkList.filter(c => c.checked).length;
    },
    totalCnt() {
      return this.checkList.length;
    },
    progressValue() {
      if (!this.checkedCnt / this.totalCnt) return 0;
      return (this.checkedCnt / this.totalCnt) * 100;
    }
  },
  watch: {
    checkList({ length }) {
      const {
        item: { id: itemId, parentId, groupId }
      } = this;
      this.SET_ITEM_ATTRS({
        itemId,
        groupId,
        parentId,
        keyValues: [{ key: "checkListCnt", value: length }]
      });
    }
  },
  methods: {
    ...mapMutations("todoItem", ["SET_ITEM_ATTRS"]),
    ...mapActions("todoDetail", ["addCheckList"]),
    enter() {
      if (isBlank(this.text)) return;
      const {
        item: { id: itemId, groupId, boardId }
      } = this;

      this.addCheckList({ itemId, groupId, boardId, title: this.text.trim() });
      this.text = "";
    },
    esc() {
      this.$refs.textRef.blur();
      this.text = "";
    }
  }
};
</script>
