<template>
  <div :class="cls" @click.stop="$emit('changeEditable', true)">
    <span>{{ inputValue }}</span>
    <v-btn
      v-if="!isDetail"
      icon
      tile
      class="cr-item-detail-btn cr-tooltip"
      :color="existDetail ? 'primary' : 'grey'"
      @click.stop="openDetail"
      @mouseover="mouseover"
      @mouseout="HIDE_TOOLTIP"
    >
      <v-icon size="20">mdi-comment-text-multiple-outline</v-icon>
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.cr-item-title {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 65px);
  min-width: 0px;
  font-size: 16px;
  padding: 2px 5px;
  margin: 0px 8px 0px 24px;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-color: transparent;
  cursor: text;

  .cr-item-detail-btn {
    position: absolute;
    top: calc(50% - 18px);
    right: 5px;
  }
  &:hover {
    border-color: rgba(0, 0, 0, 0.12);
  }
  &.cr-item-drag-mode:hover {
    border-color: transparent;
  }
  &.cr-item-sub {
    width: calc(100% - 106px);
  }

  // 상세화면에서 스타일적용
  &.cr-item-detail {
    width: 100%;
    min-height: 38px !important;
    max-height: 280px;
    margin: 0px;
    padding: 15px 23px;
    border: none;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.75rem;
    white-space: pre-wrap;
  }
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { isBlank } from "@/commons/utils/validation";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    inputValue: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters("dragDrop", ["dragMode"]),
    ...mapGetters("todoRoute", ["routeState"]),
    cls() {
      let cls = "cr-item-title";
      if (this.item.parentId > 0) cls += " cr-item-sub";
      if (this.dragMode) cls += " cr-item-drag-mode";
      if (this.isDetail) cls += " cr-item-detail";
      return cls;
    },
    existDetail() {
      const { existContent, checkListCnt, attachCnt, commentCnt } = this.item;
      return !!existContent || !!checkListCnt || !!attachCnt || !!commentCnt;
    }
  },
  methods: {
    ...mapMutations("todoTooltip", ["SET_TOOLTIP", "HIDE_TOOLTIP"]),
    ...mapActions("todoRoute", ["routeBoardAction"]),
    mouseover(event) {
      const { checkListCnt, attachCnt, commentCnt } = this.item;
      let msg = "";
      // 체크리스트: n개 | 첨부파일: n개 | 댓글: n개
      if (checkListCnt > 0) {
        msg += `체크리스트: ${checkListCnt}개`;
      }
      if (attachCnt > 0) {
        if (msg.length > 0) msg += " | ";
        msg += `첨부파일: ${attachCnt}개`;
      }
      if (commentCnt > 0) {
        if (msg.length > 0) msg += " | ";
        msg += `댓글: ${commentCnt}개`;
      }
      if (isBlank(msg)) {
        msg = "상세화면 보기";
      }

      this.SET_TOOLTIP({ msg, event, position: "top" });
    },
    openDetail() {
      const { workspaceId, boardId, filterId, viewType } = this.routeState;
      const { id: itemId, groupId, parentId } = this.item;

      this.routeBoardAction({
        workspaceId,
        boardId,
        actionObj: JSON.stringify({
          filterId,
          groupId,
          itemId,
          parentId,
          viewType
        })
      });
    }
  }
};
</script>
