<template>
  <div v-if="filterId > 0" class="mr-2">
    <v-btn x-small text outlined width="38" @click="clickOperator(filter)">
      {{ operator == "and" ? "그리고" : "또는" }}
    </v-btn>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    filter: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    filterId() {
      return this.filter?.id ?? 0;
    },
    operator() {
      return this.filter?.operator ?? "";
    }
  },
  methods: {
    ...mapMutations("todoFilter", ["SET_FILTERS"]),
    clickOperator(filter) {
      filter.operator = filter.operator == "and" ? "or" : "and";
      this.SET_FILTERS([...this.filters.map(f => ({ ...f }))]);
    }
  }
};
</script>
