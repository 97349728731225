import moment from "moment";
import headerUtils from "@/todo/store/utils/header";
const key = "prj_2";

const now = moment();
now.add(-14, "days");

export default {
  /**
   * headerUtils.TITLE, headerUtils.ADDITIONAL 두개는 필수 값 (정해진 순서대로)
   */
  genHeader: () => [
    { ...headerUtils.TITLE(), value: key + "_title" },
    { ...headerUtils.CREATED_LOG(), title: "todo.PRJ#2_등록자", width: 210 },
    {
      ...headerUtils.STATUS(),
      value: key + "_status_1",
      title: "todo.PRJ#2_중요도",
      width: 110,
      labels: [
        {
          title: "todo.PRJ#2_Low",
          color: "#66bb6a",
          value: `${key}_status_1_0`
        },
        {
          title: "todo.PRJ#2_Medium",
          color: "#ffee58",
          value: `${key}_status_1_1`
        },
        {
          title: "todo.PRJ#2_Critical",
          color: "#b71c1c",
          value: `${key}_status_1_2`
        },
        {
          title: "",
          color: "#f2f2f2",
          defaultLabel: true,
          value: `${key}_status_1_3`
        }
      ]
    },
    {
      ...headerUtils.PERSON(),
      value: key + "_person",
      title: "todo.PRJ#2_개발자",
      width: 130
    },
    {
      ...headerUtils.STATUS(),
      value: key + "_status_2",
      title: "todo.PRJ#2_상태",
      width: 110,
      labels: [
        {
          title: "todo.PRJ#2_완료",
          color: "#4caf50",
          value: `${key}_status_2_0`
        },
        {
          title: "todo.PRJ#2_진행중",
          color: "#9c27b0",
          value: `${key}_status_2_1`
        },
        {
          title: "",
          color: "#f2f2f2",
          defaultLabel: true,
          value: `${key}_status_2_2`
        }
      ]
    },
    {
      ...headerUtils.TIMELINE(),
      value: key + "_timeline_1",
      title: "todo.PRJ#2_완료일"
    },
    { ...headerUtils.TEXT(), title: "todo.PRJ#2_비고", width: 200 },
    { ...headerUtils.ADDITIONAL() }
  ],
  genGroups: () => [
    { title: "todo.PRJ#2_새로운 이슈", color: "#f44336" },
    { title: "todo.PRJ#2_처리중", color: "#3f51b5" },
    { title: "todo.PRJ#2_해결됨", color: "#9e9e9e" }
  ],
  /**
   * genGroups의 size와 genItems 배열안에 배열개수를 맞춰줘야함.
   * ex) groups: [{}, {}] , items: [[{}, {}, {}], []]
   */
  genItems: () => [
    [
      {
        [key + "_title"]: "todo.PRJ#2_item-3",
        [key + "_status_1"]: `${key}_status_1_1`
      },
      {
        [key + "_title"]: "todo.PRJ#2_item-4",
        [key + "_status_1"]: `${key}_status_1_2`
      }
    ],
    [
      {
        [key + "_title"]: "todo.PRJ#2_item-5",
        [key + "_status_1"]: `${key}_status_1_1`,
        [key + "_status_2"]: `${key}_status_2_2`
      },
      {
        [key + "_title"]: "todo.PRJ#2_item-7",
        [key + "_status_1"]: `${key}_status_1_0`,
        [key + "_status_2"]: `${key}_status_2_1`
      }
    ],
    [
      {
        [key + "_title"]: "todo.PRJ#2_item-2",
        [key + "_status_1"]: `${key}_status_1_1`,
        [key + "_status_2"]: `${key}_status_2_0`,
        [key + "_timeline_1"]: `${now.format("YYYY-MM-DD")},${now
          .add(1, "days")
          .format("YYYY-MM-DD")}`
      },
      {
        [key + "_title"]: "todo.PRJ#2_item-1",
        [key + "_status_1"]: `${key}_status_1_2`,
        [key + "_status_2"]: `${key}_status_2_0`,
        [key + "_timeline_1"]: `${now
          .add(2, "days")
          .format("YYYY-MM-DD")},${now.add(3, "days").format("YYYY-MM-DD")}`
      }
    ]
  ]
};
