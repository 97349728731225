<template>
  <div class="cr-emoji-picker">
    <div style="display: flex;">
      <v-btn small outlined color="#b1b1b1" @click="$emit('click:emoji', '')">
        <span style="color: #333 !important;">이모지 사용안함</span>
      </v-btn>
    </div>
    <div v-for="category in categories" :key="category">
      <h5 :class="'mt-3'">{{ category }}</h5>
      <div class="cr-emojis">
        <span
          v-for="{ emoji, emojiName } in emojis[category]"
          :key="emojiName"
          :title="emojiName"
          @click="$emit('click:emoji', emoji)"
        >
          {{ emoji }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-emoji-picker {
  width: 330px;
  height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px;

  h5 {
    margin-bottom: 0;
    color: #b1b1b1;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: default;
  }

  .cr-emojis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    span {
      padding: 0.2rem;
      cursor: pointer;
      border-radius: 5px;
      font-size: 25px;
      &:hover {
        cursor: pointer;
        transform: scale(1.5);
      }
    }

    &:after {
      content: "";
      flex: auto;
    }
  }
}
</style>

<script>
import emojiData from "./emoji";

export default {
  mounted() {
    Object.keys(emojiData).forEach(category => {
      this.emojis[category] = Object.keys(emojiData[category]).map(
        emojiName => ({
          emojiName,
          emoji: emojiData[category][emojiName]
        })
      );
    });

    this.categories = Object.keys(emojiData);
  },
  data() {
    return {
      categories: [],
      emojis: {}
    };
  }
};
</script>
