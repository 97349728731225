<template>
  <div class="mr-2" :style="filterId == 0 ? 'width: 196px;' : 'width: 150px;'">
    <v-select
      dense
      outlined
      hide-details
      return-object
      ref="columnref"
      label="컬럼"
      :items="header.filter(h => h.type !== 'ADDITIONAL')"
      item-text="title"
      item-value="title"
      :menu-props="{
        offsetY: true,
        closeOnClick: true,
        maxWidth: 300,
        contentClass: 'cr-modal-close-prevent'
      }"
      v-model="vModel"
      @change="
        filter.condition = {};
        filter.values = [];
        SET_FILTERS([...filters.map(f => ({ ...f }))]);
      "
      @click:append.stop="$refs.columnref.activateMenu()"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    filter: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("todoHeader", ["header"]),
    filterId() {
      return this.filter?.id ?? 0;
    },
    vModel: {
      get() {
        const [column] = this.header.filter(
          h => h.value == this.filter.column.value
        );
        return column ?? {};
      },
      set(column) {
        this.filter.column = column;
      }
    }
  },
  methods: {
    ...mapMutations("todoFilter", ["SET_FILTERS"])
  }
};
</script>
