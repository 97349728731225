<template>
  <div class="cr-todo-detail-items-wrapper">
    <div
      class="cr-todo-detail-item mb-2"
      v-for="header in headers"
      :key="header.value"
    >
      <v-hover v-slot="{ hover }">
        <Title
          ref="title"
          :header="header"
          :group="group"
          :hover="hover"
          :isDetail="true"
        />
      </v-hover>
      <component
        ref="title"
        :is="$props.components[header.type]"
        :header="header"
        :item="item"
        :isDetail="true"
        v-bind="$props"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-todo-detail-items-wrapper {
  width: 300px;
  .cr-todo-detail-item::v-deep {
    border: none;
    padding: 5px 8px;
    background: #fff;

    .cr-item-detail,
    .cr-header-title-wrapper {
      min-height: 50px;
    }

    .cr-item-detail.cr-item-created-log {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: unset;
      white-space: pre-line;
    }
  }
}
</style>

<script>
import Title from "../group/header/type/Title.vue";
import PERSON from "../group/body/type/Person.vue";
import STATUS from "../group/body/type/Status.vue";
import TIMELINE from "../group/body/type/Timeline.vue";
import CREATED_LOG from "../group/body/type/CreatedLog.vue";
import TEXT from "../group/body/type/Text.vue";
import NUMBER from "../group/body/type/Number.vue";
import LINK from "../group/body/type/Link.vue";
import CHECK from "../group/body/type/Check.vue";

export default {
  components: { Title },
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    group: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      default: () => ({})
    },
    components: {
      type: Object,
      default: () => ({
        PERSON,
        STATUS,
        TIMELINE,
        CREATED_LOG,
        TEXT,
        NUMBER,
        LINK,
        CHECK
      })
    }
  }
};
</script>
