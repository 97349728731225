<template>
  <div class="cr-item-detail-content-wrapper">
    <div
      v-if="!editable"
      class="d-flex justify-end px-3 grey--text text--light-2 text-body-2"
    >
      <span v-if="lastAuthorName">마지막 수정자:</span>
      <span class="ml-3">{{ lastAuthorName }}</span>
      <span v-if="lastAuthorEmail" class="ml-1 mr-3">
        ({{ lastAuthorEmail }})
      </span>
      <span class="text-caption">{{ updateTime }}</span>
    </div>
    <div
      v-if="!editable"
      ref="textref"
      class="cr-item-detail-content"
      :class="emptyContent ? 'cr-empty-content' : ''"
      v-html="emptyContent ? placeholder : content"
      @click="changeEditable"
    ></div>

    <TinymceEditor
      v-if="editable"
      ref="tiny"
      :useFontSelect="false"
      :useFontSizeSelect="false"
      :useLineHeight="false"
      :useAlign="false"
      :useDent="false"
      :useFullScreen="false"
      :useUndoRedo="false"
      :useImage="false"
      :toolbarLocation="'bottom'"
      @click="editorClick"
    />

    <div v-if="editable" class="d-flex justify-end cr-btns pa-2">
      <v-btn depressed outlined text class="mx-2" @click="editable = false">
        취소
      </v-btn>
      <v-btn
        depressed
        color="primary"
        :disabled="saveBtnDisabled"
        @click="save"
      >
        저장
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-item-detail-content-wrapper::v-deep {
  position: relative;
  padding-top: 8px;
  .cr-item-detail-content {
    overflow-x: scroll;
    cursor: text;
    min-height: 56px;
    padding: 16px;
    color: black;
    font-family: 나눔고딕;
    font-size: 16px;
    font-weight: 500;

    &.cr-empty-content {
      display: flex;
      color: #c8c8c8;
      align-items: center;
      padding-left: 23px;
    }
    p {
      margin: 0px;
    }
    hr {
      border-color: #ccc;
      border-style: solid;
      border-width: 1px 0 0 0;
      margin: 8px 0px;
    }
    ol,
    ul {
      margin: 16px 0px;
    }
    table:not([cellpadding]) th,
    table:not([cellpadding]) td {
      padding: 0.4rem;
    }
  }

  .tox.tox-tinymce {
    border: none;
    .tox-statusbar {
      display: none;
    }
  }

  .cr-btns {
    border-top: 1px solid #ccc;
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import TinymceEditor from "@/commons/views/editor/tinymce/TinymceEditor";
import { getFullDate } from "@/commons/utils/moment";

export default {
  components: { TinymceEditor },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      editable: false,
      clickedEditor: false,
      clickedIframe: false,
      placeholder: "내용을 입력해주세요."
    };
  },
  computed: {
    ...mapGetters("editor", { editorContent: "getContent" }),
    ...mapGetters("todoDetail", ["contentObj", "emptyContent"]),
    content() {
      return this.contentObj.content ?? "";
    },
    saveBtnDisabled() {
      return this.content === this.editorContent;
    },
    lastAuthorName() {
      if (this.contentObj.lastAuthor) {
        return this.contentObj.lastAuthor.split("||")[0];
      }
      return "";
    },
    lastAuthorEmail() {
      if (this.contentObj.lastAuthor) {
        return this.contentObj.lastAuthor.split("||")[1];
      }
      return "";
    },
    updateTime() {
      if (this.contentObj.updatedTimeMillis) {
        return getFullDate(this.contentObj.updatedTimeMillis);
      }
      return "";
    }
  },
  watch: {
    editable(n) {
      if (!n) return;
      this.$nextTick(() => {
        const { activeEditor } = window.tinymce;
        if (!activeEditor) return;

        activeEditor.focus();
        activeEditor.iframeElement.contentWindow.document.addEventListener(
          "click",
          this.iframeClick
        );
        activeEditor.iframeElement.contentWindow.document.addEventListener(
          "focusout",
          this.iframeFocusout
        );
        activeEditor.iframeElement.contentWindow.document.addEventListener(
          "keydown",
          this.iframeKeydown
        );
      });
    },
    editorContent() {
      const { activeEditor } = window.tinymce;
      if (!activeEditor) return;

      // toolbar + iframe 마진 = 77, 가로스크롤 17
      this.SET_HEIGHT(
        activeEditor.iframeElement.contentWindow.document.body.scrollHeight + 95
      );
    }
  },
  methods: {
    ...mapMutations("editor", ["SET_INIT", "SET_HEIGHT"]),
    ...mapActions("todoDetail", ["saveContent"]),
    changeEditable() {
      const height = this.$refs.textref.offsetHeight + 45;
      this.SET_INIT({ content: this.content, height });
      this.editable = true;
    },
    editorClick() {
      this.clickedEditor = true;
    },
    iframeClick(e) {
      if (e.target.tagName !== "HTML") return;
      this.clickedIframe = true;
    },
    iframeFocusout() {
      setTimeout(() => {
        if (this.clickedEditor || this.clickedIframe) {
          this.clickedEditor = false;
          this.clickedIframe = false;
        } else if (this.saveBtnDisabled) {
          this.editable = false;
        }
      }, 200);
    },
    iframeKeydown(e) {
      if (e.keyCode !== 27) return;
      this.editable = false;
    },
    async save() {
      const { item, contentObj, editorContent: content } = this;
      const { id } = contentObj;
      const { id: itemId, parentId, groupId, boardId } = item;

      await this.saveContent({
        id,
        itemId,
        parentId,
        groupId,
        boardId,
        content
      });
      this.editable = false;
    }
  }
};
</script>
