<template>
  <v-card-title class="pb-0 justify-space-between">
    <div class="d-flex align-center" style="width: 600px;">
      <v-text-field
        class="mr-2"
        ref="filterName"
        dense
        outlined
        hide-details
        v-model="name"
      ></v-text-field>
      <v-checkbox
        dense
        hide-details
        hide-spin-buttons
        class="ma-0 pa-0"
        label="기본값으로 설정"
        v-model="checkbox"
      ></v-checkbox>
    </div>
    <v-btn
      icon
      class="cr-tooltip"
      @mouseover="event => SET_TOOLTIP({ msg: '닫기', event })"
      @mouseout="HIDE_TOOLTIP"
      @click="
        CLOSE_MODAL();
        HIDE_TOOLTIP();
      "
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-card-title>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    filterName: {
      type: String,
      default: ""
    },
    isDefault: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.filterName.focus();
    });
  },
  data() {
    return {
      savedfilters: []
    };
  },
  computed: {
    name: {
      get() {
        return this.filterName;
      },
      set(name) {
        this.$emit("update:filterName", name);
      }
    },
    checkbox: {
      get() {
        return this.isDefault;
      },
      set(check) {
        this.$emit("update:isDefault", check ? 1 : 0);
      }
    }
  },
  methods: {
    ...mapMutations("todoModal", ["CLOSE_MODAL"]),
    ...mapMutations("todoTooltip", ["SET_TOOLTIP", "HIDE_TOOLTIP"])
  }
};
</script>
